import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Stack,
  VStack,
  Text,
} from "@chakra-ui/react";
import LogoHorizontal from "../../app/components/Logo/LogoHorizontal";
import { FiChevronsDown } from "react-icons/fi";
import { useSelector } from "react-redux";

export default function Landing({ props }) {
  const history = useHistory();
  const { prevLocation } = useSelector((state) => state.auth);
  const laststreamerUrl = localStorage.getItem("laststreamerUrl");

  useEffect(() => {
    if (
      laststreamerUrl &&
      prevLocation?.pathname !== laststreamerUrl &&
      prevLocation?.pathname !== "/" &&
      prevLocation?.pathname !== "/streamersettings"
    ) {
      history.push(laststreamerUrl);
    }
  }, [history, laststreamerUrl, prevLocation]);

  return (
    <>
      <Flex
        direction='column'
        align='center'
        maxW={{ xl: "1200px" }}
        minH='100vh'
        m='0 auto'
        {...props}
      >
        <VStack
          minH='70vh'
          align='center'
          direction='column'
          justify={{ base: "center", md: "space-around", xl: "space-between" }}
          m='auto'
          spacing='40px'
          p='20px'
        >
          <VStack spacing='30px' maxWidth='900px' align='left'>
            <Box my='40px'>
              <LogoHorizontal
                mt='8px'
                margin='0px'
                d='inline'
                height='70px'
                maxHeight='160px'
              />
            </Box>
            <Heading
              as='h1'
              fontSize={{ base: "40px", md: "64px", xl: "74px" }}
              // fontWeight='bold'
              // color='primary.800'
              textAlign={{ base: "center", sm: "left" }}
            >
              Streamteam helps you to create{" "}
              <Box d='inline' whiteSpace='nowrap' color={"teal.300"}>
                great memories
              </Box>{" "}
              for your viewers.
            </Heading>
            <Text
              textAlign={{ base: "center", sm: "left" }}
              fontSize='xl'
              // maxWidth='500px'
            >
              When playing with your viewers, streamteam is the right tool to
              select them in the entertaining way. Form a streamteam!{" "}
              <Box d='inline' color='gray.500'>
                #playingwithviewers #streamteam
              </Box>
            </Text>

            <Stack
              direction={["column", "row"]}
              align={{ base: "center", sm: "left" }}
            >
              <Link to='/streamersettings'>
                <Button
                  variant='primary'
                  py='4'
                  px='4'
                  lineHeight='1'
                  size='lg'
                >
                  Create your own Streamteam
                </Button>
              </Link>

              <HashLink smooth to='/#tellmemore'>
                <Button size='lg'>
                  Tell me more <FiChevronsDown />
                </Button>
              </HashLink>
            </Stack>
          </VStack>
        </VStack>
        <Center></Center>
      </Flex>
      <Flex
        direction='column'
        align='center'
        maxW={{ xl: "1200px" }}
        minH='70vh'
        m='0 auto'
        {...props}
        id='tellmemore'
        p='30px'
      >
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
          sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
          et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet.
        </Text>
      </Flex>
    </>
  );
}
