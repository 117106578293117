import React from "react";
// import EventListItem from "./EventListItem";

export default function EventList({
  events,
  getNextEvents,
  loading,
  moreEvents,
}) {
  return (
    <>
      {/* {events.length !== 0 && (
        
          {events.map((event) => (
            <EventListItem key={event.id} event={event} />
          ))}
        
      )} */}
    </>
  );
}
