import React from "react";
import { useField } from "formik";
import { Input, Box, FormLabel, Badge } from "@chakra-ui/react";

export default function MyTextInput({ label, ...props }) {
  const [field, meta] = useField(props);

  const id = `id_${props.name}`;

  return (
    <Box flex='1' position='relative'>
      <Input
        isInvalid={meta.touched && !!meta.error}
        autoComplete='off'
        variant='main'
        size='lg'
        id={id}
        {...field}
        {...props}
      />
      <FormLabel htmlFor='name'>{label}</FormLabel>

      <Badge
        marginTop='0'
        position='absolute'
        bottom='-10px'
        left='0'
        colorScheme='red'
      >
        {meta.touched && meta.error ? meta.error : null}
      </Badge>
    </Box>
  );
}
