const baseStyle = (props) => ({
  dialog: {
    // maxWidth: ["95%", "95%", "95%"],
    // minWidth: "95%",
    // bg: "#00ff00",
  },
});

export const ModalStyles = {
  baseStyle,
};
