import { Image } from "@chakra-ui/image";
import { Box } from "@chakra-ui/layout";
import logo from "../../../assets/gfx/logo_v1_h_ob.svg";
import React from "react";

export default function LogoHorizontal({
  margin = "25px",
  height = "50px",
  padding = "0px",
  ...rest
}) {
  return (
    <Box
      margin={margin}
      padding={padding}
      display='inline-block'
      height={height}
    >
      <Image src={logo} alt='logo' height={height} {...rest} />
    </Box>
  );
}
