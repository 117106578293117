import { Box, ListItem, UnorderedList } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { getAllQuizQuestions } from "../../../app/api/googlespreadsheet";

export default function PreviewImportCategory({ cat, catname }) {
  const [questions, setQuestions] = useState(undefined);

  useEffect(() => {
    if (!questions) {
      getAllQuizQuestions(cat).then((result) => {
        setQuestions(result);
      });
    }
  }, [questions, cat]);

  console.log(questions);

  if (!questions) return <Box>Loading...</Box>;

  return (
    <Box>
      <UnorderedList>
        <Box fontSize='xs' opacity='0.4'>
          ({questions.length - 1} questions)
        </Box>
        {questions
          .filter((e, index) => index > 0)
          .map((row, index) => (
            <ListItem key={index}>{row[0]}</ListItem>
          ))}
      </UnorderedList>
    </Box>
  );
}
