export const accountTypes = [
  {
    key: "discord",
    name: "Discord",
    checkregex: "^.{3,32}#[0-9]{4}$",
  },
  {
    key: "eaid",
    name: "EA ID",
    checkregex: "",
    description:
      "The EA ID can be found in the EA Client or Origin. It's your unique account name, ie 'Theefly'.",
  },
  {
    key: "xbox",
    name: "XBox",
    checkregex: "",
  },
];
