import React from "react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";

import { allSelectors } from "../../../app/api/allSelectors";
import { Box, SimpleGrid, Text } from "@chakra-ui/layout";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../../app/common/modals/modalReducer";
import { Image } from "@chakra-ui/react";

export default function SelectorSelection() {
  const dispatch = useDispatch();
  const { selectedLobby } = useSelector((state) => state.lobby);

  const hover = {
    bg: "rgba(0, 0, 0, 0.6)",
    opacity: "1",
    cursor: "pointer",
  };

  function handleClick(key) {
    dispatch(closeModal());
    dispatch(openModal({ modalType: key }));
  }

  const numUsersAvalaible = selectedLobby?.users?.filter(
    (elem) => elem.status !== "not_selected"
  ).length;

  return (
    <ModalWrapper
      size='ModalWrapper4xl'
      maxWidth={"1400px"}
      header='How do you want to reduce & select the users?'
    >
      {numUsersAvalaible === 0 && (
        <Text>
          No available users. You probably want to go back to "Join" phase.
        </Text>
      )}
      {numUsersAvalaible > 0 && (
        <SimpleGrid
          margin='auto'
          columns={{ sm: 2, md: 2, lg: 3 }}
          spacing={"15px"}
        >
          {allSelectors.map((sel, index) => (
            <Box
              h='250px'
              w={"100%"}
              key={sel.key}
              _hover={hover}
              bg='rgba(0,0,0,0.4)'
              borderRadius={"10px"}
              p='10px'
              mb='1px'
              flexDirection={"column"}
              onClick={() => handleClick(sel.key)}
            >
              <Image
                borderRadius={"5px"}
                src={`/assets/selectors/${sel.key}.jpg`}
                width={"100%"}
                height='auto'
              />
              <Box fontSize={"sm"} mt='5px'>
                {sel.text}
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      )}
    </ModalWrapper>
  );
}
