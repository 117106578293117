// import { sampleData } from "../../app/api/sampleData";
import {
  CLEAR_SELECTED_LOBBY,
  CREATE_LOBBY,
  DELETE_LOBBY,
  LISTEN_TO_SELECTED_LOBBY,
  UPDATE_LOBBY,
} from "./lobbyConstants";

const initialState = {
  lobbies: [],
  selectedLobby: null,
  retainState: false,
  initialLoad: true,
};

export default function eventReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_LOBBY:
      return {
        ...state,
        lobbies: [...state.lobbies, payload],
      };
    case UPDATE_LOBBY:
      return {
        ...state,
        lobbies: [
          ...state.lobbies.filter((lo) => lo.id !== payload.id),
          payload,
        ],
      };
    case DELETE_LOBBY:
      return {
        ...state,
        lobbies: [...state.lobies.filter((lo) => lo.id !== payload.id)],
      };
    case LISTEN_TO_SELECTED_LOBBY:
      return {
        ...state,
        selectedLobby: payload,
      };
    case CLEAR_SELECTED_LOBBY:
      return {
        ...state,
        selectedLobby: null,
      };
    default:
      return state;
  }
}
