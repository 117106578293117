import React, { useState } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { toast } from "react-toastify";
import {
  addUserAttendance,
  cancelUserAttendance,
} from "../../../app/firestore/firestoreService";
import { useSelector } from "react-redux";
import UnauthModal from "../../auth/UnauthModal";
import { Box, Heading, Stack } from "@chakra-ui/layout";
import Section from "../../../app/components/section/Section";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";

const eventImageStyle = {
  filter: "brightness(30%)",
};

const eventImageTextStyle = {
  position: "absolute",
  bottom: "5%",
  left: "2%",
  width: "100%",
  height: "auto",
  color: "white",
};

export default function EventDetailedHeader({ event, isHost, isGoing }) {
  const [loading, setLoading] = useState(false);
  const { authenticated } = useSelector((state) => state.auth);
  const [modalOpen, setModalOpen] = useState(false);

  async function handleUserJoinEvent() {
    setLoading(true);
    try {
      await addUserAttendance(event);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleUserLeaveEvent() {
    setLoading(true);
    try {
      await cancelUserAttendance(event);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {modalOpen && <UnauthModal setModalOpen={setModalOpen} />}
      <Box>
        <Section padding='0' position='relative'>
          <Image
            src={`/assets/categoryImages/${event.category}.jpg`}
            style={eventImageStyle}
          />

          <Box style={eventImageTextStyle}>
            <Stack>
              <Box>
                <Heading size='3xl'>{event.title}</Heading>
                <p>{format(event.date, "MMMM d, yyyy h:mm a")}</p>
                <p>
                  Hosted by{" "}
                  <strong>
                    <Link to={`/profile/${event.hostUid}`}>
                      {event.hostedBy}
                    </Link>
                  </strong>
                </p>
              </Box>
            </Stack>
          </Box>
        </Section>

        <Section attached overflow='auto' pb='2'>
          {!isHost && (
            <>
              {isGoing ? (
                <Button
                  onClick={handleUserLeaveEvent}
                  isLoading={loading}
                  variant='outline'
                >
                  Cancel My Place
                </Button>
              ) : (
                <Button
                  onClick={
                    authenticated
                      ? handleUserJoinEvent
                      : () => setModalOpen(true)
                  }
                  isLoading={loading}
                  variant='primary'
                >
                  JOIN THIS EVENT
                </Button>
              )}
            </>
          )}

          {isHost && (
            <Button
              as={Link}
              to={`/manage/${event.id}`}
              color='orange'
              float='right'
            >
              Manage Event
            </Button>
          )}
        </Section>
      </Box>
    </>
  );
}
