import { LISTEN_TO_QUIZ, LISTEN_TO_QUIZ_USERANSWER } from "./QuizConstants";

export function listenToQuiz(quiz) {
  return {
    type: LISTEN_TO_QUIZ,
    payload: quiz,
  };
}

export function listenToQuizUserAnswer(answer, questionId) {
  return {
    type: LISTEN_TO_QUIZ_USERANSWER,
    payload: { ...answer, questionId: questionId },
  };
}
