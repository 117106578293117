export const allSelectors = [
  {
    key: "Quiz",
    title: "Trivia Quiz",
    text: "Test the knowledge of your viewers. Only the best will proceed!",
  },
  {
    key: "SortAndCut",
    title: "Sort & Cut",
    text: "Sort users by key values and make a cut. Who joined your lobbies the most? Who tried most and failed joining a match with you? And more.",
  },
  {
    key: "Thanos",
    title: "Thanos",
    text: "Snip your fingers and half of the users are gone.",
  },
  {
    key: "Slotmachine",
    title: "Slotmachine",
    text: "Random, but nicer.",
  },
  {
    key: "QuickFinalRandom",
    title: "Boring Random",
    text: "The fastest but most boring module: No playing around, pick the winners random and instantly.",
  },
];
