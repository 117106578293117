import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

let firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "streamteam-dev.firebaseapp.com",
  databaseURL:
    "https://streamteam-dev-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "streamteam-dev",
  storageBucket: "streamteam-dev.appspot.com",
  messagingSenderId: "368607496818",
  appId: "1:368607496818:web:23e0a3425e2ea0cc54d0eb",
};

if (
  window.location.hostname === "localhost" &&
  process.env.REACT_APP_USE_EMULATOR === "true"
) {
  firebaseConfig.databaseURL =
    "http://localhost:9000/?ns=streamteam-dev-default-rtdb";
}

firebase.initializeApp(firebaseConfig);
firebase.firestore();

if (
  window.location.hostname === "localhost" &&
  process.env.REACT_APP_USE_EMULATOR === "true"
) {
  firebase.auth().useEmulator("http://localhost:9099");
}

export default firebase;
