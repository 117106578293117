import React, { useState } from "react";
import { Link as ChakraLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Box, VStack, Wrap, WrapItem, Stack, Heading } from "@chakra-ui/layout";
import { useDispatch } from "react-redux";
import {
  getStreamersUsersStatsFirestore,
  getUserProfile,
} from "../../../app/firestore/firestoreService";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";

export default function ProfileStats({ profile, streamerId }) {
  const userId = profile.id;
  const dispatch = useDispatch();
  const [streamer, setStreamer] = useState(false);
  const [stats, setStats] = useState(false);

  useFirestoreDoc({
    query: () => getUserProfile(streamerId),
    data: (profile) => setStreamer(profile),
    deps: [dispatch, streamerId],
  });

  useFirestoreDoc({
    query: () => getStreamersUsersStatsFirestore(streamerId, userId),
    data: (stats) => setStats(stats),
    deps: [dispatch, streamerId, userId],
  });

  if (!stats?.userId) return <></>;
  console.log(stats);

  function StatsNumber({ color, num, legend }) {
    if (!color) color = "white";

    return (
      <WrapItem>
        <VStack textAlign={"center"}>
          <Heading
            w={"150px"}
            borderRadius={"10px"}
            px={"10px"}
            py={"15px"}
            bg={"rgba(0,0,0,0.2)"}
            fontSize={"8xl"}
            color={color}
          >
            {num || 0}
          </Heading>
          <Box w={"150px"} fontSize={"md"} minW={"200px"}>
            {legend}
          </Box>
        </VStack>
      </WrapItem>
    );
  }

  return (
    <Stack pb={"50px"}>
      <Heading size={"lg"}>
        <ChakraLink color={"white"} as={Link} to={`/${streamer.url}`}>
          {streamer.displayName}
        </ChakraLink>
        's Lobbies
      </Heading>
      <Box pb='30px'>
        First joined on {format(stats.firstJoin, "dd MMM yyyy")}
      </Box>
      <Wrap>
        <StatsNumber color='primary' num={stats.numJoins} legend={"Joined"} />
        <StatsNumber
          color='secondary'
          num={stats.numNotSelectedStreak}
          legend={"Not selected (current streak)"}
        />

        <StatsNumber
          color='secondary'
          num={stats.numSelected}
          legend={"Played"}
        />
        <StatsNumber
          num={stats.numNotSelected}
          color='gray'
          legend={"Not Selected (Total)"}
        />
      </Wrap>
    </Stack>
  );
}
