import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box, Flex, Heading, HStack, Stack, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { signOutFirebase } from "../../app/firestore/firebaseService";
import { useToast } from "@chakra-ui/toast";

export default function LogoutPage() {
  const history = useHistory();
  const toast = useToast();
  const { prevLocation } = useSelector((state) => state.auth);
  const laststreamerUrl = localStorage.getItem("laststreamerUrl");

  async function handleSignOut() {
    try {
      await signOutFirebase();
      if (history) {
        history.push(laststreamerUrl ? laststreamerUrl : "/");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  }

  return (
    <>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        // bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>Logout</Heading>
          </Stack>
          <Box
            borderRadius='20px'
            bg={useColorModeValue("white", "gray.900")}
            // boxShadow={"lg"}
            minW={400}
            p={8}
          >
            <Text mb='10'>Do you want to sign out?</Text>
            <HStack w='full' textAlign='center' spacing='4'>
              <Button variant='primary' onClick={handleSignOut}>
                Logout
              </Button>
              <Button
                onClick={() => {
                  if (history && prevLocation) {
                    history.push(prevLocation);
                  } else {
                    history.push(laststreamerUrl ? laststreamerUrl : "/");
                  }
                }}
              >
                Cancel
              </Button>
            </HStack>
          </Box>
        </Stack>
      </Flex>
    </>
  );
}
