import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  firebaseObjectToArray,
  getEventChatRef,
} from "../../../app/firestore/firebaseService";
import { listenToEventchat } from "../eventsActions";
import EventDetailedChatForm from "./EventDetailedChatForm";
import formatDistance from "date-fns/formatDistance";
import { CLEAR_COMMENTS } from "../eventConstants";
import { createDataTree } from "../../../app/common/util/util";
import Section from "../../../app/components/section/Section";
import { Box, Stack } from "@chakra-ui/layout";
import { Avatar } from "@chakra-ui/avatar";

export default function EventDetailedChat({ eventId }) {
  const dispatch = useDispatch();
  const { comments } = useSelector((state) => state.event);
  const { authenticated } = useSelector((state) => state.auth);
  const [setShowReplyForm] = useState({
    open: false,
    commentID: null,
  });

  // function handleCloseReplyForm() {
  //   setShowReplyForm({ open: false, commentID: null });
  // }
  useEffect(() => {
    getEventChatRef(eventId).on("value", (snapshot) => {
      if (!snapshot.exists()) return;
      dispatch(
        listenToEventchat(firebaseObjectToArray(snapshot.val()).reverse())
      );
    });
    return () => {
      dispatch({ type: CLEAR_COMMENTS });
      getEventChatRef().off();
    };
  }, [eventId, dispatch]);

  return (
    <>
      {authenticated && (
        <Section
          attached
          title={
            authenticated
              ? "Chat about this event"
              : "Login to view and comment"
          }
        >
          <EventDetailedChatForm
            eventId={eventId}
            parentId={0}
            closeForm={setShowReplyForm}
          />

          <Stack mt='10'>
            {createDataTree(comments).map((comment) => (
              <Box key={comment.id} d='flex'>
                <Box w='80px' p='2'>
                  <Avatar src={comment.photoURL || "/assets/user.png"} />
                </Box>
                <Box
                  flex='1'
                  borderBottom='1px solid'
                  borderColor='rgba(255,255,255,0.1)'
                >
                  <Stack spacing='0' textAlign='left'>
                    <Box fontWeight='bold'>
                      {comment.displayName}
                      <Box
                        opacity='0.5'
                        fontSize='xs'
                        display='inline-block'
                        fontWeight='normal'
                        ml='10px'
                      >
                        <span>{formatDistance(comment.date, new Date())}</span>
                      </Box>
                    </Box>
                    <Box>
                      {comment.text.split("\n").map((text, i) => (
                        <span key={i}>
                          {text}
                          <br />
                        </span>
                      ))}
                    </Box>
                  </Stack>
                </Box>
              </Box>
            ))}
          </Stack>
        </Section>
      )}
    </>
  );
}
