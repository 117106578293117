import { Button, ButtonGroup } from "@chakra-ui/button";
import React from "react";
// import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { ImTwitch } from "react-icons/im";
import { socialLogin } from "../../app/firestore/firebaseService";

// import { openModal } from "../../app/common/modals/modalReducer";

export default function SignedOutMenu({ setAuthenticated }) {
  // const dispatch = useDispatch();
  return (
    <ButtonGroup pl='20px' pr='10px'>
      <Button
        onClick={() => socialLogin("twitch")}
        variant='twitch'
        leftIcon={<ImTwitch />}
      >
        Login with Twitch
      </Button>
      <Button variant='outline' as={NavLink} to='/login'>
        Login / Register
      </Button>
    </ButtonGroup>
  );
}
