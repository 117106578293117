import { Box } from "@chakra-ui/layout";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  asyncActionFinish,
  asyncActionStart,
} from "../../../app/async/asyncReducer";
import { closeModal, openModal } from "../../../app/common/modals/modalReducer";
import { listenToQuizUserAnswerFromFirebase } from "../../../app/firestore/firebaseService";
import { listenToQuizUserAnswer } from "./QuizActions";

export default function QuizUserAnswers({
  selectedLobby,
  currentUserProfile,
  streamerProfile,
}) {
  const dispatch = useDispatch();
  const quiz = selectedLobby.quiz;
  const lobbyId = selectedLobby.lobbyId;

  useEffect(() => {
    if (!lobbyId) return;
    if (!quiz) return;

    listenToQuizUserAnswerFromFirebase(
      lobbyId,
      quiz.currentQuestion.id,
      currentUserProfile.id
    ).on("value", (snapshot) => {
      dispatch(asyncActionStart());
      if (!snapshot.exists()) return;

      dispatch(listenToQuizUserAnswer(snapshot.val(), quiz.currentQuestion.id));
      dispatch(asyncActionFinish());
    });

    return () => {
      listenToQuizUserAnswerFromFirebase().off();
    };
  }, [lobbyId, dispatch, quiz, currentUserProfile]);

  useEffect(() => {
    if (quiz?.status !== "in_progress" || !quiz) {
      dispatch(closeModal());
    }
    if (quiz?.status === "in_progress" && quiz.currentQuestion !== undefined) {
      dispatch(
        openModal({
          modalType: "QuizUserAnswerForm",
          modalProps: {
            lobbyId: selectedLobby.lobbyId,
            currentUserProfile: currentUserProfile,
            streamerProfile: streamerProfile,
            question: quiz.currentQuestion,
          },
        })
      );
    }
  }, [dispatch, quiz, currentUserProfile, streamerProfile, selectedLobby]);

  return <Box></Box>;
}
