import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncActionFinish,
  asyncActionStart,
} from "../../../app/async/asyncReducer";
// import { closeModal } from "../../../app/common/modals/modalReducer";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import { listenToQuizFromFirebase } from "../../../app/firestore/firebaseService";
// import { countOpenSlots } from "../../../app/common/util/util";
// import { useToast } from "@chakra-ui/toast";
import {
  setLobbyGameInProgress,
  // setSelectedInLobby,
} from "../common/SelectorHelpers";
import { listenToQuiz } from "./QuizActions";
import QuizCreateForm from "./QuizCreateForm";
import QuizFininished from "./QuizFininished";
import QuizRunningForm from "./QuizRunningForm";

export default function Quiz() {
  const dispatch = useDispatch();
  const { selectedLobby } = useSelector((state) => state.lobby);
  const { quizData } = useSelector((state) => state.quiz);
  const lobbyId = selectedLobby.lobbyId;
  const quiz = selectedLobby.quiz;
  // const toast = useToast();

  useEffect(() => {
    if (!lobbyId) return;

    listenToQuizFromFirebase(lobbyId).on("value", (snapshot) => {
      dispatch(asyncActionStart());
      if (!snapshot.exists()) return;

      dispatch(listenToQuiz(snapshot.val(), snapshot.key));
      dispatch(asyncActionFinish());
    });

    return () => {
      listenToQuizFromFirebase().off();
    };
  }, [lobbyId, dispatch]);

  useEffect(() => {
    setLobbyGameInProgress(selectedLobby.lobbyId, "Quiz");

    return () => {
      setLobbyGameInProgress(selectedLobby.lobbyId, "");
    };
  }, [selectedLobby.lobbyId]);

  // function getRandom(arr, n) {
  //   var result = new Array(n),
  //     len = arr.length,
  //     taken = new Array(len);
  //   if (n > len)
  //     // throw new RangeError("getRandom: more elements taken than available");
  //     return {};
  //   while (n--) {
  //     var x = Math.floor(Math.random() * len);
  //     result[n] = arr[x in taken ? taken[x] : x];
  //     taken[x] = --len in taken ? taken[len] : len;
  //   }
  //   return result;
  // }

  // function handleCommitAction() {
  //   let availableUsers = [...selectedLobby.users].filter(
  //     (sel) =>
  //       sel.status !== "not_selected" &&
  //       sel.status !== "removed" &&
  //       sel.status !== "selected"
  //   );

  //   dispatch(closeModal());
  //   const numOpenSlots = countOpenSlots(selectedLobby);
  //   const selectedRandoms = getRandom(availableUsers, numOpenSlots);
  //   if (selectedRandoms.length)
  //     setSelectedInLobby(selectedLobby, selectedRandoms);
  //   else
  //     toast({
  //       title: "Error",
  //       description: "Sorry, no users available.",
  //       status: "error",
  //       isClosable: true,
  //     });
  // }

  // function handleCancel() {
  //   dispatch(closeModal());
  // }

  return (
    <ModalWrapper size='3xl' header=''>
      {quiz?.status === "in_progress" && (
        <QuizRunningForm
          selectedLobby={selectedLobby}
          lobbyId={selectedLobby.lobbyId}
          quizData={quizData}
        />
      )}
      {quiz?.status === "finished" && (
        <QuizFininished
          selectedLobby={selectedLobby}
          lobbyId={selectedLobby.lobbyId}
          quizData={quizData}
        />
      )}
      {!quiz?.status && <QuizCreateForm lobbyId={selectedLobby.lobbyId} />}
    </ModalWrapper>
  );
}
