import { Avatar } from "@chakra-ui/avatar";
import { Badge, HStack, Stack, Text, VStack } from "@chakra-ui/layout";
import React from "react";
import { Link } from "react-router-dom";
import Section from "../../../app/components/section/Section";

export default function EventDetailedSidebar({ attendees, hostUid }) {
  const title = (
    <span>
      {attendees.length} {attendees.length > 1 ? "People" : "Person"} Going
    </span>
  );
  return (
    <Section title={title}>
      <Stack>
        <VStack px='5'>
          {attendees.map((attendee) => (
            <HStack
              w='full'
              as={Link}
              to={`/profile/${attendee.id}`}
              key={attendee.id}
              style={{ position: "relative" }}
            >
              <Avatar size='md' src={attendee.photoURL || "/assets/user.png"} />
              <Text>
                <span>{attendee.displayName}</span>
              </Text>
              {hostUid === attendee.id && (
                <Badge color='orange' ribbon='right'>
                  Host
                </Badge>
              )}
            </HStack>
          ))}
        </VStack>
      </Stack>
    </Section>
  );
}
