import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Badge, Box } from "@chakra-ui/layout";
import { FormLabel } from "@chakra-ui/form-control";

export default function MyDateInput({ label, ...props }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  return (
    <Box position='relative'>
      <Box mt='8' mb='2' d='block'>
        <DatePicker
          {...field}
          {...props}
          style={{ backgroundColor: "#000" }}
          selected={(field.value && new Date(field.value)) || null}
          onChange={(value) => setFieldValue(field.name, value)}
        />
      </Box>
      <FormLabel top='-5' htmlFor='name'>
        {label}
      </FormLabel>

      <Badge
        marginTop='0'
        position='absolute'
        bottom='-4'
        left='0'
        colorScheme='red'
      >
        {meta.touched && meta.error ? meta.error : null}
      </Badge>
    </Box>
  );
}
