import { Box } from "@chakra-ui/layout";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Section from "../../../app/components/section/Section";
import {
  firebaseObjectToArray,
  getUserFeedRef,
} from "../../../app/firestore/firebaseService";
import { listenToFeed } from "../../profiles/profileActions";
import EventFeedItem from "./EventFeedItem";

export default function EventFeed(props) {
  const dispatch = useDispatch();
  const { feed } = useSelector((state) => state.profile);

  useEffect(() => {
    const userFeedRef = getUserFeedRef();
    userFeedRef.on("value", (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }
      const feed = firebaseObjectToArray(snapshot.val()).reverse();
      dispatch(listenToFeed(feed));
    });
    return () => {
      userFeedRef.off();
    };
  }, [dispatch]);

  return (
    <Section title='News Feed'>
      <Box>
        {feed.map((post) => (
          <EventFeedItem post={post} key={post.id} />
        ))}
      </Box>
    </Section>
  );
}
