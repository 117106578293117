import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import SignedInMenu from "./SignedInMenu";
import SignedOutMenu from "./SignOutMenu";
import {
  Flex,
  HStack,
  Link,
  Spacer,
  Box,
  // useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import LogoHorizontal from "../../app/components/Logo/LogoHorizontal";

const LogoLink = ({ children, target }) => (
  <Link
    as={NavLink}
    to={target}
    pr='20px'
    color='#ffd705'
    exact
    activeClassName='active'
    // fontStyle='italic'
    _focus={{ boxShadow: "none" }}
    _hover={{
      textDecoration: "none",
      // bg: useColorModeValue("gray.200", "rgba(255,255,255,0.01)"),
    }}
  >
    {children}
  </Link>
);

export default function NavBar({ setFormOpen }) {
  const { authenticated } = useSelector((state) => state.auth);
  // const bg = useColorModeValue("gray.50", "#17192380");
  const bg = useColorModeValue("gray.50", "gray.700");
  const laststreamerUrl = localStorage.getItem("laststreamerUrl");
  const { innerWidth } = window;

  const [headerClassName, setHeaderClassName] = useState("");

  React.useEffect(() => {
    const handleScroll = (headerClassName) => {
      if (
        headerClassName !== "menuscroll" &&
        window.pageYOffset >= 60 &&
        innerWidth < 1280
      ) {
        setHeaderClassName("menuscroll");
      } else if (headerClassName === "menuscroll" && window.pageYOffset < 60) {
        setHeaderClassName("");
      }
    };

    window.onscroll = () => handleScroll(headerClassName);
    if (innerWidth < 800) {
      setHeaderClassName("menuscroll");
    }
  }, [headerClassName, innerWidth]); // IMPORTANT, This will cause react to update depending on change of this value

  return (
    // <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>

    <Flex
      // ml={{ base: 0, md: 60 }}
      px={{ base: 0 }}
      height='60px'
      zIndex='1000'
      alignItems='center'
      // bg='rgb(20, 21, 22, 0.6)'
      // backdropFilter='blur(4px)'
      background='transparent'
      position='fixed'
      w='full'
      borderBottomWidth='0px'
      borderBottomColor={useColorModeValue("gray.200", "secondary")}
      justifyContent={{ base: "space-between" }}
    >
      <LogoLink
        target={laststreamerUrl ? laststreamerUrl : "/"}
        d='inline-block'
      >
        <HStack
          bg={bg}
          // backdropFilter='blur(4px)'
          pr='20px'
          borderBottomRightRadius='20px'
          // borderBottomWidth='2px'
          // borderBottomColor={useColorModeValue("gray.200", "gray.700")}
          alignItems={"center"}
          _hover={{
            textDecoration: "none",
            bg: useColorModeValue("gray.200", "gray.600"),
          }}
        >
          <Box
            overflow='hidden'
            transition='max-width 0.5s'
            maxWidth={
              headerClassName === "menuscroll" || innerWidth < 800
                ? "60px"
                : "380px"
            }
          >
            <Box m='0px'>
              <LogoHorizontal
                margin='2px'
                padding='4px'
                ml='10px'
                mt='7px'
                d='inline'
                height='40px'
                htmlHeight='40px'
                htmlWidth='auto'
                maxWidth='none'
              />
            </Box>
          </Box>
          <Box
            d='inline'
            color='white'
            _hover={{ textDecoration: "none" }}
            pt='9px'
            fontSize='xl'
            // fontWeight='bold'
            fontFamily='Rajdhani'
          >
            {laststreamerUrl}
          </Box>

          {/* <QuickLink /> */}
        </HStack>
      </LogoLink>
      <Spacer />
      <HStack
        mt='0px'
        // bg={useColorModeValue("passive", "passive")}
        bg={useColorModeValue("gray.50", "gray.700")}
        color='white'
        p='0'
        paddingBottom='0px'
        paddingTop='0px'
        paddingLeft='0px'
        paddingRight='0px'
        height='60px'
        borderBottomLeftRadius='20px'
        // borderBottomWidth='2px'
        // borderBottomColor={useColorModeValue("gray.200", "secondary")}
      >
        {authenticated ? <SignedInMenu /> : <SignedOutMenu />}
        {/* <IconButton
          icon={colorMode === "light" ? <SunIcon /> : <MoonIcon />}
          variant='ghost'
          colorScheme='cyan'
          _focus={{ boxShadow: "none" }}
          aria-label='Color mode switcher'
          onClick={toggleColorMode}
        >
          Switch Mode
        </IconButton> */}
      </HStack>
    </Flex>
  );
}
