import { Box } from "@chakra-ui/layout";
import React, { useCallback, useState } from "react";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";

export function useLocalStorage(key, initialState) {
  const [value, setValue] = useState(localStorage.getItem(key) ?? initialState);
  const updatedSetValue = useCallback(
    (newValue) => {
      if (newValue === initialState || typeof newValue === "undefined") {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, newValue);
      }
      setValue(newValue ?? initialState);
    },
    [initialState, key]
  );
  return [value, updatedSetValue];
}

export function soundOn() {
  return !localStorage.getItem("disableSound");
}

export default function SoundSwitch({ cancelSounds }) {
  const [disableSound, setDisableSound] = useLocalStorage(
    "disableSound",
    false
  );

  function handleToggle() {
    // console.log(stops);
    setDisableSound(!disableSound);
    if (!disableSound) {
      cancelSounds();
    }
  }

  return (
    <Box
      _hover={{ cursor: "pointer" }}
      fontSize='30px'
      onClick={() => handleToggle()}
    >
      {disableSound ? <GiSpeakerOff /> : <GiSpeaker />}
    </Box>
  );
}
