import firebase from "../config/firebase";
import {
  deleteFromFirebaseStorage,
  getLobbyFromFirebase,
} from "./firebaseService";

const db = firebase.firestore();

if (
  window.location.hostname === "localhost" &&
  process.env.REACT_APP_USE_EMULATOR === "true"
) {
  db.useEmulator("localhost", 8080);
  firebase.functions().useEmulator("localhost", 5001);
}

export function dataFromSnapshot(snapshot) {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data();

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof firebase.firestore.Timestamp) {
        data[prop] = data[prop].toDate();
      }
    }
  }

  return {
    ...data,
    id: snapshot.id,
  };
}

export function fetchEventsFromFirestore(
  filter,
  startDate,
  limit,
  lastDocSnapshot = null
) {
  const user = firebase.auth().currentUser;
  let eventsRef = db
    .collection("events")
    .orderBy("date")
    .startAfter(lastDocSnapshot)
    .limit(limit);
  switch (filter) {
    case "isGoing":
      return eventsRef
        .where("attendeesIds", "array-contains", user.uid)
        .where("date", ">=", startDate);
    case "isHost":
      return eventsRef
        .where("hostUid", "==", user.uid)
        .where("date", ">=", startDate);

    default:
      return eventsRef.where("date", ">=", startDate);
  }
}

export function deleteQuizitemInFirestore(userId, itemIdd) {
  return db
    .collection("quizitems")
    .doc(userId)
    .collection("questions")
    .doc(itemIdd)
    .delete();
}

export function listenToQuizitemsFromFirestore(userId) {
  return db
    .collection("quizitems")
    .doc(userId)
    .collection("questions")
    .orderBy("createdAt", "desc");
}

export function listenToEventFromFirestore(eventId) {
  return db.collection("events").doc(eventId);
}

export function addEventToFirestore(event) {
  const user = firebase.auth().currentUser;

  return db.collection("events").add({
    ...event,
    hostUid: user.uid,
    hostedBy: user.displayName,
    hostPhotoURL: user.photoURL || null,
    attendees: firebase.firestore.FieldValue.arrayUnion({
      id: user.uid,
      displayName: user.displayName,
      photoURL: user.photoURL || null,
    }),
    attendeesIds: firebase.firestore.FieldValue.arrayUnion(user.uid),
  });
}

export function updateEventInFirestore(event) {
  return db.collection("events").doc(event.id).update(event);
}

export function deleteEventInFirestore(eventId) {
  return db.collection("events").doc(eventId).delete();
}

export function cancelEventToggle(event) {
  return db.collection("events").doc(event.id).update({
    isCancelled: !event.isCancelled,
  });
}

export function setUserProfileDataTwitch(user, profile) {
  return db
    .collection("users")
    .doc(user.uid)
    .set({
      displayName: profile.preferred_username || profile.sub,
      email: profile.email,
      photoURL: profile.picture || null,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
}

export function setUserProfileDataDiscord(user, profile) {
  // return db
  //   .collection("users")
  //   .doc(user.uid)
  //   .set({
  //     displayName: profile.preferred_username || profile.sub,
  //     email: profile.email,
  //     photoURL: profile.picture || null,
  //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //   });
}

export function setUserProfileDataGoogle(user, profile) {
  return db.collection("users").doc(user.uid).set({
    displayName: null,
    email: user.email,
    photoURL: null,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
}

export function setUserProfileData(user) {
  return db
    .collection("users")
    .doc(user.uid)
    .set({
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL || null,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
}

export function setUserFirstStreamer(streamerUid) {
  const user = firebase.auth().currentUser;
  if (!user) return;
  return db
    .collection("users")
    .doc(user.uid)
    .update({
      firstStreamerUid: streamerUid || null,
    });
}

export function getUserProfile(userId) {
  return db.collection("users").doc(userId);
}

export function getStreamerProfile(userId) {
  return db.collection("users").doc(userId);
}

export function getStreamerUidByURL(url) {
  if (!url || url === "") {
    return new Promise((resolve) => {
      resolve(false);
    });
  }
  var docRef = db.collection("streamerurls").doc(url);

  return docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data().uid;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
}

export async function updateQuizItem(itemId, values) {
  const user = firebase.auth().currentUser;

  try {
    return await db
      .collection("quizitems")
      .doc(user.uid)
      .collection("questions")
      .doc(itemId)
      .update({
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        ...values,
      });
  } catch (error) {
    throw error;
  }
}

export async function addQuizItem(values) {
  const user = firebase.auth().currentUser;

  try {
    return await db
      .collection("quizitems")
      .doc(user.uid)
      .collection("questions")
      .doc()
      .set({
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        ...values,
      });
  } catch (error) {
    throw error;
  }
}

export async function saveAfterMatchFeedback(values) {
  const user = firebase.auth().currentUser;

  try {
    return await db
      .collection("aftermatchfeedback")
      .doc(values.lobbyId)
      .collection("users")
      .doc(user.uid)
      .set({
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        ...values,
      });
  } catch (error) {
    throw error;
  }
}

export async function deleteUserProfile(profile) {
  const user = firebase.auth().currentUser;

  try {
    //streamerURL
    var delete_query = db
      .collection("streamerurls")
      .where("uid", "==", user.uid);
    delete_query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete();
      });
    });

    await db
      .collection("users_deleted")
      .doc(user.uid)
      .set({
        deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
        ...profile,
      });

    await user.delete();

    // Users
    return await db.collection("users").doc(user.uid).delete();
  } catch (error) {
    throw error;
  }
}

export async function updateUserProfile(profile) {
  const user = firebase.auth().currentUser;

  try {
    // Updating the Auth Account Data (name/Photo)
    if (user.displayName !== profile.displayName) {
      await user.updateProfile({
        displayName: profile.displayName,
      });
    }
    if (profile.photoURL) {
      await user.updateProfile({
        photoURL: profile.photoURL,
      });
    }

    if (profile.url) {
      await updateStreamerURL(user.uid, profile.url);
    }

    let updatedProfile = profile;
    if (profile.joinedLobby) {
      updatedProfile = {
        ...profile,
        joinedStreamers: firebase.firestore.FieldValue.arrayUnion(
          profile.joinedStreamerId
        ),
      };
    }

    return await db.collection("users").doc(user.uid).update(updatedProfile);
  } catch (error) {
    throw error;
  }
}

export async function updateStreamerURL(id, url) {
  try {
    var delete_query = db
      .collection("streamerurls")
      .where("uid", "==", id)
      .where("url", "!=", url);
    delete_query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete();
      });
    });
  } catch (error) {
    throw error;
  }

  return await db.collection("streamerurls").doc(url).set({
    uid: id,
    url: url,
  });
}

export async function updateUserProfilePhoto(downloadURL, filename) {
  const user = firebase.auth().currentUser;
  const userDocRef = db.collection("users").doc(user.uid);
  try {
    const userDoc = await userDocRef.get();
    if (userDoc.data().photoFilename) {
      deleteFromFirebaseStorage(userDoc.data().photoFilename);
    }
    await db.collection("users").doc(user.uid).update({
      photoURL: downloadURL,
      photoFilename: filename,
    });

    await setMainPhoto(downloadURL);

    return await user.updateProfile({
      photoURL: downloadURL,
    });
    // }

    // return await db.collection("users").doc(user.uid).collection("photos").add({
    //   name: filename,
    //   url: downloadURL,
    // });
  } catch (error) {
    throw error;
  }
}

export function getUserPhotos(userUid) {
  return db.collection("users").doc(userUid).collection("photos");
}

export function getCurrentUser() {
  return firebase.auth().currentUser;
}

export async function setMainPhoto(photoURL) {
  const user = firebase.auth().currentUser;
  const today = new Date();
  const eventDocQuery = db
    .collection("events")
    .where("attendeesIds", "array-contains", user.uid)
    .where("date", ">=", today);
  // const userFollowingRef = db
  //   .collection("following")
  //   .doc(user.uid)
  //   .collection("userFollowing");
  const batch = db.batch();

  // batch.update(db.collection("users").doc(user.uid), {
  //   photoURL: photoURL,
  // });

  try {
    const eventsQuerySnap = await eventDocQuery.get();
    for (let i = 0; i < eventsQuerySnap.docs.length; i++) {
      let eventDoc = eventsQuerySnap.docs[i];
      if ((eventDoc.data().hostUid = user.uid)) {
        batch.update(eventsQuerySnap.docs[i].ref, {
          hostPhotoURL: photoURL,
        });
      }
      batch.update(eventsQuerySnap.docs[i].ref, {
        attendees: eventDoc.data().attendees.filter((attendee) => {
          if (attendee.id === user.uid) {
            attendee.photoURL = photoURL;
          }
          return attendee;
        }),
      });
    }
    // const userFollowingSnap = await userFollowingRef.get();
    // userFollowingSnap.docs.forEach((docRef) => {
    //   let followingDocRef = db
    //     .collection("following")
    //     .doc(docRef.id)
    //     .collection("userFollowers")
    //     .doc(user.uid);
    //   batch.update(followingDocRef, {
    //     photoURL: photo.url,
    //   });
    // });

    return await batch.commit();

    // return user.updateProfile({
    //   photoURL: photo.url,
    // });
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}

export function deletePhotoFromCollection(photoId) {
  const userUid = firebase.auth().currentUser.uid;
  return db
    .collection("users")
    .doc(userUid)
    .collection("photos")
    .doc(photoId)
    .delete();
}

export async function createLobbylogInFirestore(lobbyId, values) {
  const user = firebase.auth().currentUser;

  await db.collection("lobbylogs").doc(user.uid).set({
    streamerDisplayName: user.displayName,
    streamerUid: user.uid,
  });

  await db
    .collection("lobbylogs")
    .doc(user.uid)
    .collection("lobbies")
    .doc(lobbyId)
    .set({
      streamerDisplayName: user.displayName,
      userCount: 0,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      ...values,
    });

  return db
    .collection("lobbylogs")
    .doc(user.uid)
    .collection("lobbies")
    .doc(lobbyId)
    .collection("log")
    .add({
      action: "lobby_created",
      userId: user.uid,
      displayName: user.displayName,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
}

export function deleteDiscordChannelViaFirestore(lobbyId) {
  const values = {
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    status: "tobedeleted",
  };

  return db
    .collection("discordchannels")
    .doc(lobbyId)
    .update({
      ...values,
    });
}

export async function createDiscordChannelsViaFirestore(lobbyId) {
  let users = [];
  let user = [];

  const selectedLobby = await getLobbyFromFirebase(lobbyId);

  // console.log(selectedLobby);
  // return;

  selectedLobby.selectedUsers?.forEach((teams, tindex) => {
    teams.forEach((player) => {
      let discordaccount = player.accounts?.filter((e) => e.type === "discord");

      if (discordaccount) {
        user = {
          team: tindex,
          userId: player.userId,
          displayName: player.displayName,
          discordUserId: discordaccount[0]?.discordId || null,
          discordaccount: discordaccount[0]?.value || null,
        };
        users.push(user);
      }
    });
  });

  const values = {
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    status: "pending",
    numTeams: selectedLobby.selectedUsers.length,
    lobbyName: `${selectedLobby.selectedUsers[0][0].displayName}'s Streamteam`,
    lobbyId: lobbyId,
    users: users,
  };

  return db
    .collection("discordchannels")
    .doc(lobbyId)
    .set({
      ...values,
    });
}

export function updateLobbylogInFirestore(lobbyId, streamerId, values) {
  return db
    .collection("lobbylogs")
    .doc(streamerId)
    .collection("lobbies")
    .doc(lobbyId)
    .update({
      ...values,
    });
}

export function addEntryLobbylogInFirestore(lobbyId, streamerId, action) {
  const user = firebase.auth().currentUser;
  return db
    .collection("lobbylogs")
    .doc(streamerId)
    .collection("lobbies")
    .doc(lobbyId)
    .collection("log")
    .add({
      action: action,
      userId: user.uid,
      displayName: user.displayName,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
}

export function getStreamersUsersStatsFirestore(streamerId, userId) {
  return db
    .collection("streamers_users")
    .doc(streamerId)
    .collection("users")
    .doc(userId);
}

export async function listenToStreamersUsersStatsFirestore(streamerId, userId) {
  return db
    .collection("streamers_users")
    .doc(streamerId)
    .collection("users")
    .doc(userId);
}

export async function updateStreamersUsersStatsFirestore(
  streamerId,
  userId,
  type
) {
  let newValues = {};
  let incrementSelected = 0;
  let incrementNotSelected = 0;

  if (type === "selected") {
    incrementSelected = 1;
    incrementNotSelected = 0;
  } else {
    incrementSelected = 0;
    incrementNotSelected = 1;
  }

  const doc = await db
    .collection("streamers_users")
    .doc(streamerId)
    .collection("users")
    .doc(userId)
    .get();

  newValues = {
    numSelected:
      doc.data().numSelected === undefined
        ? incrementSelected
        : doc.data().numSelected + incrementSelected,
    numNotSelected:
      doc.data().numNotSelected === undefined
        ? incrementNotSelected
        : doc.data().numNotSelected + incrementNotSelected,
  };

  // if selected, reset the notSelectedStreak to 0
  if (type === "selected") {
    newValues.numNotSelectedStreak = 0;
  } else {
    newValues.numNotSelectedStreak =
      doc.data().numNotSelectedStreak === undefined
        ? incrementNotSelected
        : doc.data().numNotSelectedStreak + incrementNotSelected;
  }

  return db
    .collection("streamers_users")
    .doc(streamerId)
    .collection("users")
    .doc(userId)
    .update(newValues);
}

export function addUserAttendance(event) {
  const user = firebase.auth().currentUser;
  return db
    .collection("events")
    .doc(event.id)
    .update({
      attendees: firebase.firestore.FieldValue.arrayUnion({
        id: user.uid,
        displayName: user.displayName,
        photoURL: user.photoURL || null,
      }),
      attendeesIds: firebase.firestore.FieldValue.arrayUnion(user.uid),
    });
}

export async function cancelUserAttendance(event) {
  const user = firebase.auth().currentUser;
  try {
    const eventDoc = await db.collection("events").doc(event.id).get();
    return db
      .collection("events")
      .doc(event.id)
      .update({
        attendeesIds: firebase.firestore.FieldValue.arrayRemove(user.uid),
        attendees: eventDoc
          .data()
          .attendees.filter((attendee) => attendee.id !== user.uid),
      });
  } catch (error) {
    throw error;
  }
}

export function getUserEventsQuery(activeTab, userUid) {
  let eventsRef = db.collection("events");
  const today = new Date();
  switch (activeTab) {
    case 1: // Past Events
      return eventsRef
        .where("attendeesIds", "array-contains", userUid)
        .where("date", "<=", today)
        .orderBy("date", "desc");
    case 2: // Hosted Events
      return eventsRef.where("hostUid", "==", userUid).orderBy("date");

    default:
      return eventsRef
        .where("attendeesIds", "array-contains", userUid)
        .where("date", ">=", today)
        .orderBy("date", "desc");
  }
}

export async function followUser(profile) {
  const user = firebase.auth().currentUser;
  const batch = db.batch();

  try {
    batch.set(
      db
        .collection("following")
        .doc(user.uid)
        .collection("userFollowing")
        .doc(profile.id),
      {
        displayName: profile.displayName,
        photoURL: profile.photoURL,
        uid: profile.id,
      }
    );
    batch.update(db.collection("users").doc(user.uid), {
      followingCount: firebase.firestore.FieldValue.increment(1),
    });
    return await batch.commit();
  } catch (error) {
    throw error;
  }
}

export async function unfollowUser(profile) {
  const user = firebase.auth().currentUser;
  const batch = db.batch();

  try {
    batch.delete(
      db
        .collection("following")
        .doc(user.uid)
        .collection("userFollowing")
        .doc(profile.id)
    );
    batch.update(db.collection("users").doc(user.uid), {
      followingCount: firebase.firestore.FieldValue.increment(-1),
    });
    return await batch.commit();
  } catch (error) {
    throw error;
  }
}

export function getFollowersCollection(profileId) {
  return db.collection("following").doc(profileId).collection("userFollowers");
}

export function getFollowingCollection(profileId) {
  return db.collection("following").doc(profileId).collection("userFollowing");
}

export function getFollowingDoc(profileId) {
  const userUid = firebase.auth().currentUser.uid;
  return db
    .collection("following")
    .doc(userUid)
    .collection("userFollowing")
    .doc(profileId)
    .get();
}

export async function removeUserAccountToFirestore(
  currentUserProfile,
  accountType
) {
  const userUid = firebase.auth().currentUser.uid;
  let accountOld;
  if (currentUserProfile.accounts)
    currentUserProfile.accounts.forEach((a) => {
      if (a.type === accountType) accountOld = a;
    });
  const batch = db.batch();
  try {
    if (accountOld) {
      batch.update(db.collection("users").doc(userUid), {
        accounts: firebase.firestore.FieldValue.arrayRemove(accountOld),
      });
    }
    batch.update(db.collection("users").doc(userUid), {
      accountsKeys: firebase.firestore.FieldValue.arrayRemove(accountType),
    });
    return await batch.commit();
  } catch (error) {
    throw error;
  }
}

export async function addUserAccountToFirestore(currentUserProfile, account) {
  const userUid = firebase.auth().currentUser.uid;
  let accountOld;

  if (currentUserProfile.accounts)
    currentUserProfile.accounts.forEach((a) => {
      if (a.type === account.type) accountOld = a;
    });

  const batch = db.batch();

  try {
    if (accountOld) {
      batch.update(db.collection("users").doc(userUid), {
        accounts: firebase.firestore.FieldValue.arrayRemove(accountOld),
      });
    }
    batch.update(db.collection("users").doc(userUid), {
      accounts: firebase.firestore.FieldValue.arrayUnion(account),
      accountsKeys: firebase.firestore.FieldValue.arrayUnion(account.type),
    });

    return await batch.commit();
  } catch (error) {
    throw error;
  }
}

// export async function removeJoinedLobbyForAllUsers(lobbyId) {
//   if (!lobbyId) return;
//   try {
//     var where_query = db
//       .collection("users")
//       .where("joinedLobby", "==", lobbyId);

//     where_query.get().then(function (querySnapshot) {
//       querySnapshot.forEach(function (doc) {
//         doc.ref.update({ joinedLobby: null, joinedStreamerURL: null });
//       });
//     });
//   } catch (error) {
//     throw error;
//   }
// }
