export const sortAndCutTypes = [
  {
    key: "numNotSelected",
    name: "joined but not played",
    description: "How many times have they tried but failed to play with you",
  },
  {
    key: "numNotSelectedStreak",
    name: "joined but not played in a row",
    description:
      "How many times have they tried but failed to play with you (Streak)",
  },
  {
    key: "numJoins",
    name: "lobby joins",
    description: "How many times have the users joined your lobbies.",
  },
  {
    key: "firstJoin",
    name: "member since",
    checkregex: "Since when the users joined one of your lobby the first time.",
  },
];
