import { Box, Center, HStack, Text, VStack } from "@chakra-ui/layout";
import React from "react";
import MyAvatar from "../../../app/components/MyAvatar/MyAvatar";

export default function LobbySelectedUsers({ selectedLobby }) {
  return (
    <Center>
      <VStack>
        <HStack>
          {selectedLobby.selectedUsers?.map((team, tindex) => (
            <HStack
              key={`selecteduser_team_${tindex}`}
              mt='5'
              mb='3'
              spacing='1'
              position='relative'
            >
              {team.length &&
                team.map((selectedUser, pindex) => (
                  <Box
                    key={`selecteduser_user_${pindex}`}
                    className='circle'
                    _before={{ borderColor: "primary" }}
                    position='relative'
                    textAlign='center'
                    w='81px'
                    h='80px'
                  >
                    <MyAvatar
                      // ignoreFallback='false'

                      name={selectedUser.displayName}
                      src={selectedUser.photoURL}
                      size='lg'
                    />
                    <Text textAlign='center' mt='2' fontSize='xs'>
                      {selectedUser.displayName}
                    </Text>
                  </Box>
                ))}
              {selectedLobby.selectedUsers[1] && tindex === 0 && (
                <Box pl='4px' pb='4'>
                  vs.
                </Box>
              )}
            </HStack>
          ))}
        </HStack>
      </VStack>
    </Center>
  );
}
