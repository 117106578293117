import { Button } from "@chakra-ui/button";
import { Box, Flex, Link, Heading, Spacer, Text } from "@chakra-ui/layout";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import React, { useState } from "react";
import { ImExit } from "react-icons/im";
import { accountTypes } from "../../../app/api/accounttypes";
import { removeUser } from "./LobbyAdminMenu";
import SpoilerValue from "./SpoilerValue";

export default function LobbySelectedUsersAccounts({
  selectedLobby,
  currentUserProfile,
  streamerProfile,
}) {
  const [confirmOpen, setConfirmOpen] = useState(undefined);
  const cancelRef = React.useRef();
  const confirmMethods = [];
  confirmMethods["handleRemoveUser"] = function () {
    removeUser(confirmOpen.handleParams);
    setConfirmOpen(undefined);
  };

  const lobbyId = selectedLobby.lobbyId;
  const accountWidth = selectedLobby.accountsNeeded
    ? Math.round(1 / selectedLobby.accountsNeeded.length)
    : "100";

  return (
    <>
      <Box
        // mt='20px'
        padding='0'
        // attached
        bg={"gray.900"}
        mb='50px'
        mt='20px'
        // borderLeft='2px dashed orange'
        // borderRight='2px dashed orange'
        borderRadius='20px'
        // p='10px 10px'
        // title={
        //   currentUserProfile.id === streamerProfile.id
        //     ? "Invite these players to your game:"
        //     : "You are in the Streamteam!"
        // }
      >
        {/* {currentUserProfile.id !== streamerProfile.id && ( */}
        <Heading
          textAlign='center'
          pt='20px'
          // color='teal.300'
          fontWeight={"bold"}
          textTransform='uppercase'
          size='md'
          opacity={"0.5"}
          letterSpacing={"1px"}
          fontStyle={"italic"}
        >
          {currentUserProfile.id !== streamerProfile.id && (
            <>You are in this streamteam!</>
          )}
          {currentUserProfile.id === streamerProfile.id && (
            <>This is your streamteam!</>
          )}
        </Heading>

        {/* )} */}
        {selectedLobby.accountsNeeded?.filter((a) => a === "discord").length >
          0 &&
          !selectedLobby.dontCreateDiscordChannels && (
            <Flex>
              <Spacer />
              <Button
                as={Link}
                href={selectedLobby.discordInviteLink}
                isExternal
                size='md'
                m='20px'
                variant='primary'
                textTransform={"uppercase"}
                // _hover={{ textDecoration: "none" }}
              >
                &#160; Join Discord
              </Button>
              <Spacer />
            </Flex>
          )}

        <Box
          mt='20px'
          borderRadius='20px'
          borderWidth='0px'
          // borderStyle='dashed'
          // borderColor='primary'
          overflow='hidden'
        >
          <Table
            position='relative'
            left='-2px'
            width='101%'
            mt='0px'
            variant='striped'
          >
            {selectedLobby.accountsNeeded && (
              <Thead bg='rgba(0,0,0,0.2)'>
                <Tr>
                  <Th></Th>
                  {selectedLobby.accountsNeeded?.map((type) => (
                    <Th fontSize={"lg"} key={`header_${type}`}>
                      {accountTypes
                        .filter((a) => a.key === type)
                        .map((a) => a.name)}
                    </Th>
                  ))}
                </Tr>
              </Thead>
            )}

            {selectedLobby.selectedUsers?.map((team, tindex) => (
              <Tbody key={`team_${tindex}`}>
                {team.length &&
                  team.map((selectedUser, pindex) => (
                    <Tr
                      key={`row_${selectedUser.id}`}
                      position='relative'
                      borderLeft='3px solid transparent'
                    >
                      <Td w={`${accountWidth}%`} fontWeight='bold'>
                        <Flex>
                          <Box flex='1' fontSize={"lg"}>
                            {selectedUser.displayName}
                          </Box>
                          {currentUserProfile.id === streamerProfile.id && (
                            <Box
                              flex='0 0 80px'
                              fontSize='xs'
                              _hover={{
                                cursor: "pointer",
                                color: "red",
                              }}
                              onClick={() =>
                                setConfirmOpen({
                                  title: "Remove User?",
                                  body: "Remove the user from your team, so cou can select another one?",
                                  handle: "handleRemoveUser",
                                  handleParams: {
                                    lobbyId: lobbyId,
                                    selectedLobby: selectedLobby,
                                    tindex: tindex,
                                    pindex: pindex,
                                    id: selectedUser.id,
                                  },
                                })
                              }
                            >
                              <Box
                                role='group'
                                position='relative'
                                overflow='hidden'
                              >
                                <ImExit
                                  style={{
                                    display: "inline",
                                    verticalAlign: "middle",
                                  }}
                                />
                                <Text
                                  d='none'
                                  _groupHover={{ display: "inline-block" }}
                                  position='absolute'
                                  left='16px'
                                  top='2px'
                                >
                                  Kick
                                </Text>
                              </Box>
                            </Box>
                          )}
                        </Flex>
                      </Td>
                      {selectedLobby.accountsNeeded?.map((a) => (
                        <Td
                          key={`account_${pindex}_${a}`}
                          w={`${accountWidth}%`}
                        >
                          {selectedUser.accounts
                            ?.filter((elem) => elem.type === a)
                            .map((elem) => (
                              <SpoilerValue
                                key={`value_${pindex}_${a}`}
                                alwaysVisible={
                                  currentUserProfile.id !== streamerProfile.id
                                }
                                value={elem.value}
                              />
                            ))}
                        </Td>
                      ))}
                    </Tr>
                  ))}

                {selectedLobby.selectedUsers[2] && tindex === 0 && (
                  <Tr bg='rgba(0,0,0,0.2)'>
                    <Th>VS.</Th>
                    {selectedLobby.accountsNeeded.map((type) => (
                      <Th key={`secondheader_${type}`}>
                        {accountTypes
                          .filter((a) => a.key === type)
                          .map((a) => a.name)}
                      </Th>
                    ))}
                  </Tr>
                )}
              </Tbody>
            ))}
          </Table>
        </Box>
      </Box>
      <AlertDialog
        isOpen={confirmOpen !== undefined}
        leastDestructiveRef={cancelRef}
        onClose={() => setConfirmOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent backgroundColor={"modalBg"}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {confirmOpen && confirmOpen.title}
            </AlertDialogHeader>

            <AlertDialogBody>{confirmOpen && confirmOpen.body}</AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme='green'
                onClick={function () {
                  confirmMethods[confirmOpen.handle]();
                }}
                mr={3}
              >
                Confirm
              </Button>
              <Button ref={cancelRef} onClick={() => setConfirmOpen(undefined)}>
                Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
