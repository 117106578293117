import { Box, Flex } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listenToLobbyFromFirebase } from "../../../app/firestore/firebaseService";
import LoadingComponent from "../../../app/layout/LoadingComponent";

import { listenToSelectedLobby } from "../lobbyActions";
import Section from "../../../app/components/section/Section";
import {
  asyncActionFinish,
  asyncActionStart,
} from "../../../app/async/asyncReducer";
import LobbyAdminMenu from "./LobbyAdminMenu";
import UnauthModal from "../../auth/UnauthModal";
import { Progress } from "@chakra-ui/progress";
import { useHistory } from "react-router";
import LobbySelectedUsers from "./LobbySelectedUsers";
import LobbyUserActions from "./LobbyUserActions";
import LobbyUsers from "./LobbyUsers";
import LobbySelectedUsersAccounts from "./LobbySelectedUsersAccounts";
import LobbyFinished from "./LobbyFinished";
import QuizUserAnswers from "../../selectors/quiz/QuizUserAnswers";

export default function LobbyDetailed({
  lobbyId,
  lobbyIdUrl,
  streamerProfile,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [lobbyDone] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { loading, error } = useSelector((state) => state.async);
  const { selectedLobby } = useSelector((state) => state.lobby);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const currentUserLobbyUserId = selectedLobby?.users?.filter(
    (u) => u.userId === currentUserProfile?.id || null
  )[0]?.id;

  useEffect(() => {
    if (!lobbyId) return;

    listenToLobbyFromFirebase(lobbyId).on("value", (snapshot) => {
      dispatch(asyncActionStart());
      if (!snapshot.exists()) return;
      dispatch(listenToSelectedLobby(snapshot.val(), snapshot.key));
      dispatch(asyncActionFinish());
    });

    return () => {
      listenToLobbyFromFirebase().off();
    };
  }, [lobbyId, lobbyIdUrl, dispatch, streamerProfile.createdLobby]);

  // Show Info Toast when Lobby finished
  if (selectedLobby?.status === "finished" && lobbyDone === false) {
    // setLobbyDone(true);
    // dispatch(clearSelectedLobby());
    // toast({
    //   title: "Lobby finished",
    //   description: "The Lobby has been finished.",
    //   status: "info",
    //   isClosable: true,
    // });
  }

  if (
    (loading && !selectedLobby && !currentUserProfile) ||
    (!selectedLobby && !error)
  )
    return <LoadingComponent content={`Loading Lobby Data.(${lobbyId})..`} />;

  let currentUserIsSelected = 0;
  selectedLobby.selectedUsers?.map((team) => {
    currentUserIsSelected =
      currentUserIsSelected +
      team.filter((u) => u.userId === currentUserProfile?.id).length;
    return true;
  });

  // If user is selected, send him to lobbyId URL (for after-match screen later on)
  if (selectedLobby.status === "all_selected" && currentUserIsSelected > 0) {
    if (lobbyIdUrl !== selectedLobby.lobbyId) {
      const url = `/${streamerProfile.url}/${selectedLobby.lobbyId}`;
      if (history.location.pathname !== url) {
        history.push(url);
      }
    }
  }

  // Is user isnt selected anymore, send him back to streamer Home
  if (lobbyIdUrl && currentUserIsSelected === 0) {
    const url = `/${streamerProfile.url}`;
    if (history.location.pathname !== url) {
      history.push(url);
    }
  }

  if (selectedLobby.status === "finished") {
    return (
      <LobbyFinished
        currentUserProfile={currentUserProfile}
        streamerProfile={streamerProfile}
        selectedLobby={selectedLobby}
      />
    );
  }

  return (
    <>
      {modalOpen && <UnauthModal setModalOpen={setModalOpen} />}
      {/* {selectedLobby.game && (
        <Text opacity='0.5' mt='-15px' mb='5px' textAlign='center'>
          to play <b>{selectedLobby.game}</b> ,{" "}
          {format(selectedLobby.created, "dd MMM yyyy, HH:ii")}
        </Text>
      )} */}
      <Section pb='10px' attached={true} padding='0'>
        <LobbyAdminMenu
          lobbyId={lobbyId}
          selectedLobby={selectedLobby}
          currentUserProfile={currentUserProfile}
          streamerProfile={streamerProfile}
        />

        <LobbySelectedUsers selectedLobby={selectedLobby} />

        {selectedLobby.streamerId !== currentUserProfile?.id &&
          selectedLobby.gameinprogress !== "" && (
            <Flex
              w='100%'
              height='calc( 100% - 40px )'
              position='absolute'
              left='0'
              top='40px'
              bg='rgba(0,0,0,0.9)'
              borderBottomRadius='20px'
              textAlign='center'
              zIndex='5'
              overflow='hidden'
            >
              <Box m='auto' w='full'>
                {`Selection "${selectedLobby.gameinprogress}" in progress, check the stream and see what ${streamerProfile.displayName} is doing!`}
              </Box>
              {selectedLobby.gameinprogress !== "" && (
                <Progress
                  position='absolute'
                  bottom='0px'
                  left='-20px'
                  width='100%'
                  size='xs'
                  mx='20px'
                  isIndeterminate
                />
              )}
            </Flex>
          )}
      </Section>

      {selectedLobby.streamerId !== currentUserProfile?.id && (
        <>
          {selectedLobby.status !== "all_selected" && (
            <LobbyUserActions
              selectedLobby={selectedLobby}
              currentUserProfile={currentUserProfile}
              streamerProfile={streamerProfile}
            />
          )}
          {currentUserLobbyUserId &&
            selectedLobby.users.filter(
              (u) => u.userId === currentUserProfile.id
            )[0].status === "joined" && (
              <QuizUserAnswers
                selectedLobby={selectedLobby}
                currentUserProfile={currentUserProfile}
                streamerProfile={streamerProfile}
              />
            )}
        </>
      )}

      {selectedLobby.status === "all_selected" && currentUserIsSelected > 0 && (
        <LobbySelectedUsersAccounts
          selectedLobby={selectedLobby}
          currentUserProfile={currentUserProfile}
          streamerProfile={streamerProfile}
        />
      )}

      {selectedLobby.status !== "all_selected" && (
        <LobbyUsers
          selectedLobby={selectedLobby}
          streamerProfile={streamerProfile}
        />
      )}
    </>
  );
}
