import React from "react";
import { format } from "date-fns";
import { Box, Flex, Text } from "@chakra-ui/layout";
import Section from "../../../app/components/section/Section";
import { CalendarIcon, InfoIcon } from "@chakra-ui/icons";
import { HiLocationMarker } from "react-icons/hi";

export default function EventDetailedInfo({ event }) {
  return (
    <Box>
      <Section>
        <Flex>
          <Box p='2'>
            <InfoIcon />
          </Box>
          <Box p='2'>
            <Text>{event.description}</Text>
          </Box>
        </Flex>
      </Section>
      <Section attached>
        <Flex>
          <Box p='2'>
            <CalendarIcon />
          </Box>
          <Box p='2'>
            <span>{format(event.date, "MMMM d, yyyy h:mm a")}</span>
          </Box>
        </Flex>
      </Section>
      <Section attached>
        <Flex>
          <Box p='2'>
            <HiLocationMarker />
          </Box>
          <Box p='2'>
            <span>{event.venue}</span>
          </Box>
        </Flex>
      </Section>
    </Box>
  );
}
