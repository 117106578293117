import { mode } from "@chakra-ui/theme-tools";

export const FormLabelStyles = {
  // Styles for the base style
  baseStyle: (props) => ({
    // opacity: "0.3",
    color: mode("gray.400", "gray.500")(props),
    fontSize: "sm",
    padding: "0",
    fontWeight: "normal",
    position: "absolute",
    top: 2,
    left: "0",
  }),
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {},
};
