import { Button, ButtonGroup } from "@chakra-ui/button";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../app/common/modals/modalReducer";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import { countOpenSlots } from "../../../app/common/util/util";
import { useToast } from "@chakra-ui/toast";
import {
  setLobbyGameInProgress,
  setSelectedInLobby,
} from "../common/SelectorHelpers";

export default function QuickFinalRandom() {
  const dispatch = useDispatch();
  const { selectedLobby } = useSelector((state) => state.lobby);
  const toast = useToast();

  useEffect(() => {
    setLobbyGameInProgress(selectedLobby.lobbyId, "QuickFinalRandom");

    return () => {
      setLobbyGameInProgress(selectedLobby.lobbyId, "");
    };
  }, [selectedLobby.lobbyId]);

  function getRandom(arr, n) {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      // throw new RangeError("getRandom: more elements taken than available");
      return {};
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

  function handleCommitAction() {
    let availableUsers = [...selectedLobby.users].filter(
      (sel) =>
        sel.status !== "not_selected" &&
        sel.status !== "removed" &&
        sel.status !== "selected"
    );

    dispatch(closeModal());
    const numOpenSlots = countOpenSlots(selectedLobby);
    const selectedRandoms = getRandom(availableUsers, numOpenSlots);
    if (selectedRandoms.length)
      setSelectedInLobby(selectedLobby, selectedRandoms);
    else
      toast({
        title: "Error",
        description: "Sorry, no users available.",
        status: "error",
        isClosable: true,
      });
  }

  function handleCancel() {
    dispatch(closeModal());
  }

  return (
    <ModalWrapper size='xl' header='Quick Random'>
      <p>Select all needed players randomly?</p>
      <ButtonGroup mt='10'>
        <Button variant='primary' onClick={() => handleCommitAction()}>
          Yes!
        </Button>
        <Button onClick={() => handleCancel()}>No. Cancel.</Button>
      </ButtonGroup>
    </ModalWrapper>
  );
}
