import { Box, Flex } from "@chakra-ui/layout";
import React from "react";

export default function Vcontainer({
  children,
  title,
  attached,
  padding,
  ...rest
}) {
  return (
    <Flex
      maxW='container.xl'
      pt='80px'
      minH='100vh'
      pb='80px'
      // className='smoothresize'
      position='relative'
      m='auto'
      mx={["2", "2", "2", "2", "auto"]}
      {...rest}
    >
      <Box m='auto' w='full'>
        {children}
      </Box>
    </Flex>
  );
}
