import { Form, Formik } from "formik";
import React, { useState } from "react";

import MyTextArea from "../../../app/common/form/MyTextArea";
import MyTextInput from "../../../app/common/form/MyTextInput";
import * as Yup from "yup";
import {
  deleteUserProfile,
  updateUserProfile,
} from "../../../app/firestore/firestoreService";
import { FormControl } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/button";
import { useHistory } from "react-router-dom";
import { Box, Stack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { useLocation } from "react-router";
import { Link as ChakraLink } from "@chakra-ui/react";
import { signOutFirebase } from "../../../app/firestore/firebaseService";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";

export default function SettingsAboutTab({ currentUserProfile }) {
  const toast = useToast();
  const search = useLocation().search;
  const history = useHistory();
  const laststreamerUrl = localStorage.getItem("laststreamerUrl");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const cancelRef = React.useRef();

  // PhotoURL might be given by GET param for Bot Registrations
  const photoURL = new URLSearchParams(search).get("photoURL")
    ? new URLSearchParams(search).get("photoURL")
    : currentUserProfile.photoURL;

  function handleDelete() {
    toast({
      title: "Deleted",
      description: "Your account has been deleted.",
      status: "warning",
      isClosable: true,
    });
    setConfirmOpen(false);
    if (history) {
      history.push(laststreamerUrl ? laststreamerUrl : "/");
    }

    deleteUserProfile(currentUserProfile)
      .then(() => {
        // dispatch(closeModal());
        signOutFirebase();
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          isClosable: true,
        });
      });
  }

  return (
    <Box>
      <Formik
        initialValues={{
          displayName: currentUserProfile.displayName,
          description: currentUserProfile.description || "",
          hometown: currentUserProfile.hometown || "",
          country: currentUserProfile.country || "",
          photoURL: photoURL || null,
        }}
        validationSchema={Yup.object({
          displayName: Yup.string().max(20).required(),
          hometown: Yup.string().max(30),
          country: Yup.string().max(20),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await updateUserProfile(values);
            toast({
              title: "Success",
              description: "Data has been updated",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            // toast.error(error.message);
            console.log(error.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <FormControl>
              <Stack w='full'>
                <MyTextInput name='displayName' label='Display Name' />
                <Stack
                  d='flex'
                  w='full'
                  direction={["column", "column", "row"]}
                >
                  <MyTextInput flex='1' name='hometown' label='Hometown' />
                  <MyTextInput flex='1' name='country' label='Country' />
                  {photoURL && <input type='hidden' name='photoURL' />}
                </Stack>
                <MyTextArea
                  rows='10'
                  name='description'
                  label='Description'
                  placeholder='Tell us something about you...'
                />

                <Button
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting || !isValid}
                  type='submit'
                  variant='primary'
                >
                  Update profile
                </Button>
                <Box>
                  <ChakraLink
                    onClick={() => setConfirmOpen(true)}
                    float={"right"}
                    mt={"50px"}
                  >
                    Delete Account
                  </ChakraLink>
                </Box>
              </Stack>
            </FormControl>
          </Form>
        )}
      </Formik>

      <AlertDialog
        isOpen={confirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setConfirmOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Account
            </AlertDialogHeader>

            <AlertDialogBody>
              This will DELETE your account - are you sure?
              <Box color={"red"} fontWeight={"bold"} mt='10px'>
                We can't undelete your account afterwards!
              </Box>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setConfirmOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={() => handleDelete()} ml={3}>
                DELETE Account
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
