import { Button } from "@chakra-ui/button";
// import { useColorMode } from "@chakra-ui/color-mode";
import { useDisclosure } from "@chakra-ui/hooks";

import { Box, Container } from "@chakra-ui/layout";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/modal";
import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";

// import MyAvatar from "../../app/components/MyAvatar/MyAvatar";
// import { openModal } from "../../app/common/modals/modalReducer";
// import { decrement, increment } from "./testReducer";

function DrawerExample({ isOpen, onClose, btnRef }) {
  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Create your account</DrawerHeader>

        <DrawerBody>
          <p>Drawer Body Content</p>
        </DrawerBody>

        <DrawerFooter>
          <Button variant='outline' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme='blue'>Save</Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default function Sandbox(params) {
  // const dispatch = useDispatch();
  const [revealButton, setRevealButton] = useState(false);
  // const data = useSelector((state) => state.test.data);
  // const { loading } = useSelector((state) => state.async);

  // const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  useEffect(() => {
    setRevealButton(true);
  }, []);

  window["PayPal"]["Donation"]
    ["Button"]({
      env: "production",
      hosted_button_id: "2EQ8Z4WYYLFV8",
      image: {
        src: "https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif",
        alt: "Donate with PayPal button",
        title: "PayPal - The safer, easier way to pay online!",
      },
    })
    .render("#donate-button");

  return (
    <>
      <Container pt='200px'>
        <h1>Testing 123</h1>

        <Box>
          <div id='donate-button-container'>
            <div id='donate-button'></div>
          </div>
        </Box>

        <DrawerExample onClose={onClose} btnRef={btnRef} isOpen={isOpen} />
        <Button ref={btnRef} colorScheme={"teal"} onClick={onOpen}>
          Open
        </Button>

        <Box className={revealButton ? "trapdoor trapdoor_hover" : "trapdoor"}>
          <Box className='top door'></Box>
          <Box className='bottom door'></Box>
          <Button ref={btnRef} colorScheme={"teal"} onClick={onOpen}>
            Open
          </Button>
        </Box>
      </Container>
    </>
  );
}
