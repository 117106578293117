// https://htmltidy.net/
// 1) Copy Text from Google Doc
// 2) Past in htmltidy
// 3) Click "Tidy"
// 4) Past Result here

export default function TextTermsOfUse() {
  return (
    <div class='legal'>
      <h1>Intro</h1>
      <p>
        Streamteam is a website that serves as a tool and service for online
        gamers, more specifically video streamers and their viewers. If a
        streamer wants to play live with some of their viewers, Streamteam helps
        to pick a small number of users from a bigger pool of interested users.
        The streamer opens a lobby and the viewers can sign up for that lobby.
        After signin phase, the streamer has several moduls that can be used to
        reduce the number of users to end up with the number that is needed to
        form a team for the specific game. Let&rsquo;s say the streamer
        want&rsquo;s to play an online game which is being played 5vs5, so the
        streamer needs 4 viewers out of maybe dozens or even hundres, thousands
        of viewers that signed up.
      </p>
      <ul>
        <li aria-level='1'>
          Users and Streamers can register and login on the website
        </li>
        <ul>
          <li aria-level='2'>With eMail + Password + Nickname or</li>
          <li aria-level='2'>via &ldquo;Social Login&rdquo; ( OAuth )</li>
          <ul>
            <li aria-level='3'>Twitch Account</li>
            <li aria-level='3'>Google Account dev.</li>
          </ul>
          <li aria-level='2'>
            After Registration users can add Details to their profile
          </li>
          <ul>
            <li aria-level='3'>Photo or Image as Profile Pic</li>
            <li aria-level='3'>Hometown, Country, Birthday</li>
            <li aria-level='3'>About Text, small description of themselves</li>
          </ul>
        </ul>
        <li aria-level='1'>The website is being hosted on Google, using</li>
        <ul>
          <li aria-level='2'>Google Hosting</li>
          <li aria-level='2'>Google Firebase and Firestone as databases</li>
          <li aria-level='2'>Google Analytics</li>
        </ul>
        <li aria-level='1'>
          &ldquo;Cookies&rdquo; that are being used
          <ul>
            <li aria-level='1'>....</li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
