import React from "react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import AccountFormAdd from "./AccountFormAdd";

export default function AccountForm({
  accountType,
  accountTypes,
  currentUserProfile,
  handleClose,
}) {
  return (
    <ModalWrapper>
      <AccountFormAdd
        accountType={accountType}
        accountTypes={accountTypes}
        currentUserProfile={currentUserProfile}
        handleClose={handleClose}
      />
    </ModalWrapper>
  );
}
