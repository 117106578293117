import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Heading,
  Link,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/layout";
import { AiTwotoneEdit } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { Button } from "@chakra-ui/button";

import {
  deleteQuizitemInFirestore,
  listenToQuizitemsFromFirestore,
} from "../../../app/firestore/firestoreService";

import { useDispatch } from "react-redux";
import { openModal } from "../../../app/common/modals/modalReducer";

export default function StreamerSettingsQuizTab({ currentUserProfile }) {
  const [quizitems, setQuizitems] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    const query = listenToQuizitemsFromFirestore(currentUserProfile.id);

    query.onSnapshot((querySnapshot) => {
      let items = [];
      querySnapshot.forEach((doc) => {
        let item = {
          id: doc.id,
          ...doc.data(),
        };
        items.push(item);
      });
      setQuizitems(items);
    });
  }, [currentUserProfile.id]);

  const answers = [1, 2, 3, 4];
  const answersNames = { 1: "A", 2: "B", 3: "C", 4: "D" };

  function handleDelete(id) {
    deleteQuizitemInFirestore(currentUserProfile.id, id);
  }

  let categories = [];
  if (quizitems?.length) {
    quizitems.forEach((elem) => {
      if (elem.category) categories[elem.category] = elem.category;
    });
  }

  return (
    <Box>
      <Heading>Quiz</Heading>
      <Text>
        To use the Quiz module, you need to provide your own questions and
        answers.
      </Text>

      <Button
        mt='20px'
        variant='primary'
        onClick={() => {
          dispatch(
            openModal({
              modalType: "StreamerSettingsQuizEditForm",
              modalProps: {
                currentUserProfile: currentUserProfile,
                categories: categories,
                answers: answers,
              },
            })
          );
        }}
      >
        Add Question
      </Button>

      <Button
        mt='20px'
        float='right'
        ml='10px'
        variant='solid'
        // size='lg'
        onClick={() => {
          dispatch(
            openModal({
              modalType: "StreamerSettingsQuizImportForm",
              modalProps: {
                currentUserProfile: currentUserProfile,
              },
            })
          );
        }}
      >
        Import Questions
      </Button>

      <Box mt='55px'>
        <Heading mb='5' size='md'>
          Your Questions ({quizitems?.length})
        </Heading>
        <Box mb='50px'>
          {(!quizitems || quizitems?.length === 0) && (
            <Text opacity='0.3' fontStyle='italic'>
              No questions added yet. You might want to{" "}
              <Link
                onClick={() => {
                  dispatch(
                    openModal({
                      modalType: "StreamerSettingsQuizImportForm",
                      modalProps: {
                        currentUserProfile: currentUserProfile,
                      },
                    })
                  );
                }}
              >
                import some
              </Link>{" "}
              as a starting point.
            </Text>
          )}
          {quizitems &&
            quizitems.map((item) => (
              <Grid
                p='0px'
                mb='2px'
                key={item.id}
                // maxW='1200px'
                // templateColumns='repeat(4, 1fr)'
                gap='2px'
              >
                <Box
                  borderLeft='3px solid'
                  borderColor='primary'
                  position='relative'
                  colSpan='1'
                  bg='rgba(255,255,255,0.2)'
                  p='5px'
                  // h='40px'
                  // lineHeight='40px'
                >
                  <Link
                    mr='10px'
                    _hover={{ color: "primary" }}
                    onClick={() => {
                      dispatch(
                        openModal({
                          modalType: "StreamerSettingsQuizEditForm",
                          modalProps: {
                            currentUserProfile: currentUserProfile,
                            item: item,
                            answers: answers,
                            categories: categories,
                          },
                        })
                      );
                    }}
                  >
                    <AiTwotoneEdit
                      style={{
                        verticalAlign: "bottom",
                        fontSize: "24px",
                        marginRight: "3px",
                        paddingBottom: "4px",
                        display: "inline",
                      }}
                    />
                  </Link>
                  [{item.category}] <b>{item.question}</b>
                  <Link
                    position='absolute'
                    _hover={{ color: "primary" }}
                    fontSize='sm'
                    right='5px'
                    top='10px'
                  >
                    <BsTrash
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete(item.id)}
                    />
                  </Link>
                </Box>
                <Box
                  colSpan='2'
                  borderLeft='3px solid'
                  borderColor='transparent'
                  position='relative'
                  // w='100%'
                  pl='20px'
                  bg='rgba(255,255,255,0.1)'
                >
                  <Wrap m='10px' spacing='1px'>
                    {answers.map((e) => (
                      <WrapItem minW='40%'>
                        <Text
                          p='8px 15px'
                          w='100%'
                          backgroundColor='rgba(0,0,0,0.3)'
                        >
                          <Box
                            d='inline'
                            mr='10px'
                            fontWeight='bold'
                            opacity='0.2'
                          >
                            {answersNames[e]}
                          </Box>
                          <Box
                            d='inline'
                            color={item.correctAnswer === e ? "primary" : ""}
                          >
                            {item[`answer${e}`]}
                          </Box>
                        </Text>
                      </WrapItem>
                    ))}
                  </Wrap>
                </Box>
              </Grid>
            ))}
        </Box>
      </Box>
    </Box>
  );
}
