import { LISTEN_TO_QUIZ, LISTEN_TO_QUIZ_USERANSWER } from "./QuizConstants";

const initialState = {
  quizData: null,
};

export default function quizReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LISTEN_TO_QUIZ:
      return {
        ...state,
        quizData: payload,
      };
    case LISTEN_TO_QUIZ_USERANSWER:
      return {
        ...state,
        quizUserAnswer: payload,
      };
    default: {
      return state;
    }
  }
}
