import {
  updateLobbyInFirebase,
  updateLobbySelectedUsersBulkInFirebase,
  updateLobbyUsersBulkStatusInFirebase,
  updateLobbyUserStatusInFirebase,
} from "../../../app/firestore/firebaseService";
import {
  createDiscordChannelsViaFirestore,
  deleteDiscordChannelViaFirestore,
  updateLobbylogInFirestore,
} from "../../../app/firestore/firestoreService";

// Set Selected Users in Firebase ////////////////////////////////////////////()
export async function setSelectedInLobby(selectedLobby, selectedRandoms) {
  const lobbyId = selectedLobby.lobbyId;
  let num = 0;
  let selectedUsersUpdates = [];
  selectedLobby.selectedUsers.forEach((team, tindex) => {
    selectedUsersUpdates[tindex] = [];
    team.forEach((userslot, sindex) => {
      if (!userslot.id) {
        selectedUsersUpdates[tindex][sindex] = selectedRandoms[num];
        num++;
      }
    });
  });

  await updateLobbySelectedUsersBulkInFirebase(lobbyId, selectedUsersUpdates);

  await updateLobbyInFirebase(lobbyId, {
    status: "all_selected",
  });
  await updateLobbylogInFirestore(lobbyId, selectedLobby.streamerId, {
    status: "all_selected",
  });

  if (selectedLobby.dontCreateDiscordChannels !== true)
    createDiscordChannelsViaFirestore(lobbyId);

  // Set all other users to not selected / failed
  const usersToUpdate = selectedLobby.users.filter(
    (elem) => selectedRandoms.filter((sel) => sel.id === elem.id).length === 0
  );
  updateLobbyUsersBulkStatusInFirebase(lobbyId, usersToUpdate, "not_selected");
}

export async function removeFromSelectedUsers(
  lobbyId,
  selectedLobby,
  tindex,
  pindex,
  id
) {
  const selectedUsersUpdates = [...selectedLobby.selectedUsers];
  selectedUsersUpdates[tindex][pindex] = {
    userId: "",
    displayName: "",
  };

  await updateLobbySelectedUsersBulkInFirebase(lobbyId, selectedUsersUpdates);

  await updateLobbyInFirebase(lobbyId, {
    status: "in_progress",
  });
  await updateLobbylogInFirestore(lobbyId, selectedLobby.streamerId, {
    status: "in_progress",
  });

  if (!selectedLobby.dontCreateDiscordChannels)
    deleteDiscordChannelViaFirestore(lobbyId);

  // Set all other users to not selected / failed
  let usersToUpdate = [];

  // console.log("user to be removed id " + id);
  selectedLobby.users.forEach((user) => {
    if (user.id === id) {
      // Is User the removed User?
      // console.log("user is removed " + user.id);
      updateLobbyUserStatusInFirebase(lobbyId, user.id, "removed");
    } else if (user.status === "selected" && user.id !== id) {
      // User is SelectedUser so user status stays
      // console.log("user is selected " + user.id);
      // updateLobbyUserStatusInFirebase(lobbyId, user.id, "selected");
    } else {
      // console.log("user is other " + user.id);
      usersToUpdate.push(user);
    }
  });

  // console.log(usersToUpdate);
  // all others back to "joined"
  if (usersToUpdate.length)
    updateLobbyUsersBulkStatusInFirebase(lobbyId, usersToUpdate, "joined");
}

// Sleep //////////////////////////////////////////////////////////////////////
export function Sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function setLobbyGameInProgress(lobbyId, status) {
  updateLobbyInFirebase(lobbyId, {
    gameinprogress: status,
  });
}
