import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ImTwitch } from "react-icons/im";
import { socialLogin } from "../../app/firestore/firebaseService";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
  Box,
  VStack,
  StackDivider,
  Text,
} from "@chakra-ui/react";

import { Button } from "@chakra-ui/button";
import { useHistory } from "react-router";

export default function UnauthModal({ setModalOpen }) {
  const [setOpen] = useState(true);
  const history = useHistory();
  const { prevLocation } = useSelector((state) => state.auth);
  const laststreamerUrl = localStorage.getItem("laststreamerUrl");

  function handleClose() {
    if (!history) {
      // setOpen(false);
      setModalOpen(false);
      return;
    }
    if (history && prevLocation) {
      history.push(prevLocation);
    } else {
      history.push(laststreamerUrl ? laststreamerUrl : "/");
    }
    if (typeof setOpen === "function") setOpen(false);
  }

  function handleOpenLoginModal(targetURL) {
    // setOpen(false);
    history.push(targetURL);
  }

  return (
    <Modal
      // isCentered
      size='lg'
      isOpen={true}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent p={[0, 0]} borderRadius='20px' mt={[50, 50]}>
        <ModalHeader>You need to be logged in to do that</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={[1, 5]}>
          <VStack>
            <p>Please either login or register to see this content</p>

            <ButtonGroup>
              <Button
                w={200}
                variant='primary'
                onClick={() => handleOpenLoginModal("/login")}
              >
                Login
              </Button>
              <Button
                w={200}
                variant='secondary'
                onClick={() => handleOpenLoginModal("/register")}
              >
                Register
              </Button>
            </ButtonGroup>
            <StackDivider p='30' />
            <Button
              onClick={() => socialLogin("twitch")}
              variant='twitch'
              leftIcon={<ImTwitch />}
            >
              Login with Twitch
            </Button>
            <StackDivider p='30' />
            <Box style={{ textAlign: "center" }}>
              <Text>Or click Cancel to continue as a guest</Text>
              <Button onClick={handleClose}>Cancel</Button>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>

    // <Modal open={open} size='mini' onClose={handleClose}>
    //   <Modal.Header content='You need to be signed in to do that' />
    //   <Modal.Content>
    //     <p>Please either login or register to see this content</p>
    //     <Button.Group widths={4}>
    //       <Button
    //         fluid
    //         color='teal'
    //         content='Login'
    //         onClick={() => handleOpenLoginModal("LoginForm")}
    //       />
    //       <Button.Or />
    //       <Button
    //         fluid
    //         color='green'
    //         content='Register'
    //         onClick={() => handleOpenLoginModal("RegisterForm")}
    //       />
    //     </Button.Group>
    //     <Divider />
    //     <div style={{ textAlign: "center" }}>
    //       <p>Or click Cancel to continue as a guest</p>
    //       <Button onClick={handleClose} content='Cancel' />
    //     </div>
    //   </Modal.Content>
    // </Modal>
  );
}
