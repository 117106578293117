import React from "react";
import EventDetailedHeader from "./EventDetailedHeader";
import EventDetailedInfo from "./EventDetailedInfo";
import EventDetailedChat from "./EventDetailedChat";
import EventDetailedSidebar from "./EventDetailedSidebar";
import { useSelector } from "react-redux";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";
import { listenToEventFromFirestore } from "../../../app/firestore/firestoreService";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { listenToSelectedEvent } from "../eventsActions";
import { Container, Flex, Stack } from "@chakra-ui/layout";

export default function EventDetailedPage({ match }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const event = useSelector(
    (state) =>
      // state.event.events.find((e) => e.id === match.params.id)
      state.event.selectedEvent
  );
  const { loading, error } = useSelector((state) => state.async);
  const isHost = event?.hostUid === currentUser?.uid;
  const isGoing = event?.attendees?.some((a) => a.id === currentUser?.uid);

  useFirestoreDoc({
    query: () => listenToEventFromFirestore(match.params.id),
    data: (event) => dispatch(listenToSelectedEvent(event)),
    deps: [match.params.id, dispatch],
  });

  if (loading || (!event && !error))
    return <LoadingComponent content='Loading event...' />;

  if (error) return <Redirect to='/error' />;

  return (
    <Container maxW='container.xl'>
      <Flex pt='5'>
        <Stack flex='1'>
          <EventDetailedHeader
            event={event}
            isGoing={isGoing}
            isHost={isHost}
          />
          <EventDetailedInfo event={event} />
          <EventDetailedChat eventId={event.id} />
        </Stack>
        <Stack w='400px' pl='10'>
          <EventDetailedSidebar
            attendees={event?.attendees}
            hostUid={event.hostUid}
          />
        </Stack>
      </Flex>
    </Container>
  );
}
