import React, { useEffect } from "react";

export default function PaypalDonateButton() {
  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById("donate-button") !== null) {
        document.getElementById("donate-button").innerHTML = "";
        window["PayPal"]["Donation"]
          ["Button"]({
            env: "production",
            hosted_button_id: "2EQ8Z4WYYLFV8",
            image: {
              src: "https://pics.paypal.com/00/s/MDk1YjVhNjYtNTJjZi00Y2NhLTljZGUtYjNhNWJiNDhlZjM3/file.PNG",
              alt: "Donate with PayPal button",
              title: "PayPal - The safer, easier way to pay online!",
            },
          })
          .render("#donate-button");
      }
    }, 0);
  }, []);

  return (
    <div id='donate-button-container'>
      <div id='donate-button'></div>
    </div>
  );
}
