import React from "react";
import Vcontainer from "../../app/components/Vcontainer/Vcontainer";
import TextTermsOfUse from "./texts/TextTermsofuse";
import TextImprint from "./texts/TextImprint";

export default function LegalText({ module }) {
  let content;

  switch (module) {
    case "terms-of-use":
      content = <TextTermsOfUse />;
      break;
    case "imprint":
      content = <TextImprint />;
      break;

    default:
      content = <TextImprint />;
      break;
  }

  return (
    <>
      <Vcontainer maxW='960px' margin='auto'>
        {content}
      </Vcontainer>
    </>
  );
}
