import { Button } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box, Flex, Link, Stack } from "@chakra-ui/layout";
import React from "react";
import { ImGoogle, ImTwitch } from "react-icons/im";

import { AiOutlineMail } from "react-icons/ai";
import { openModal } from "../../../app/common/modals/modalReducer";

import {
  socialLink,
  socialUnlink,
} from "../../../app/firestore/firebaseService";
import { useDispatch, useSelector } from "react-redux";
import { verifyAuth } from "../../auth/authActions";
import { useToast } from "@chakra-ui/toast";
import { Spinner } from "@chakra-ui/spinner";
import {
  asyncActionFinish,
  asyncActionStart,
} from "../../../app/async/asyncReducer";
import LoadingComponent from "../../../app/layout/LoadingComponent";
// import axios from "axios";

export default function SettingsConnectionsTab(currentUser) {
  const dispatch = useDispatch();
  const toast = useToast();

  const { loading } = useSelector((state) => state.async);
  const lineColor = useColorModeValue("gray.200", "gray.900");

  // const getUserInfo = async (auth) => {
  //   const endpoint = "https://api.twitch.tv/helix/users";
  //   try {
  //     const { data } = await axios.get(endpoint, {
  //       headers: {
  //         "Client-ID": process.env.REACT_APP_TWITCH_CLIENTID,
  //         "Authorization": `Bearer ${auth.credential.idToken}`,
  //       },
  //     });
  //     return data;
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  async function handleSocialLink(provider) {
    try {
      dispatch(asyncActionStart());
      await socialLink(provider)
        .then((result) => {
          // const data = getUserInfo(result).then(console.log(data));

          console.log(result.credential.idToken);
          // Accounts successfully linked.
          // var credential = result.credential;
          // var user = result.user;
          dispatch(verifyAuth());
          dispatch(asyncActionFinish());
        })
        .catch((error) => {
          // Handle Errors here.
          dispatch(asyncActionFinish());
        });
    } catch (error) {
      console.log(error);
      dispatch(asyncActionFinish());
    }
  }

  async function handleUnlink(provider) {
    try {
      dispatch(asyncActionStart());
      socialUnlink(provider)
        .then(() => {
          // Auth provider unlinked from account
          toast({
            title: "Done",
            description: "Connection has been unlinked.",
            status: "info",
            isClosable: true,
          });
          dispatch(verifyAuth());
          dispatch(asyncActionFinish());
        })
        .catch((error) => {
          // An error happened
          // ...
        });
    } catch (error) {
      console.log(error);
    }
  }

  function handlePassword() {
    dispatch(openModal({ modalType: "EmailPasswordForm" }));
  }

  // console.log(currentUser.currentUser.providerData);

  if (loading) return <LoadingComponent content='Working..' />;

  const isLastProvider = currentUser.currentUser.providerData.length === 1;

  let providerData = [];
  providerData["oidc.twitch.tv"] = currentUser.currentUser.providerData?.filter(
    (sel) => sel.providerId === "oidc.twitch.tv"
  );
  providerData["oidc.discord"] = currentUser.currentUser.providerData?.filter(
    (sel) => sel.providerId === "oidc.discord"
  );
  providerData["google.com"] = currentUser.currentUser.providerData?.filter(
    (sel) => sel.providerId === "google.com"
  );
  providerData["password"] = currentUser.currentUser.providerData?.filter(
    (sel) => sel.providerId === "password"
  );

  return (
    <>
      <Stack>
        <Flex minH='40px' borderBottom={`1px solid ${lineColor}`} w='100%'>
          <Box d='inline' flex='0 0 220px'>
            <ImTwitch size='20px' style={{ display: "inline" }} /> <b>Twitch</b>
          </Box>
          <Box flex='1'>
            {loading && <Spinner />}
            {!loading && (
              <>
                {providerData["oidc.twitch.tv"].length === 0 && (
                  <Button onClick={() => handleSocialLink("twitch")}>
                    Connect Twitch
                  </Button>
                )}
                {providerData["oidc.twitch.tv"].length > 0 && (
                  <>
                    <Box d='inline-block' mr='2'>
                      UserID #
                      {providerData["oidc.twitch.tv"].map((p) => {
                        return p.uid;
                      })}
                    </Box>
                    {!isLastProvider && (
                      <Link
                        d='inline-block'
                        onClick={() => handleUnlink("oidc.twitch.tv")}
                      >
                        Unlink Twitch
                      </Link>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        </Flex>

        {/* <Flex minH='40px' borderBottom={`1px solid ${lineColor}`} w='100%'>
          <Box d='inline' flex='0 0 220px'>
            <RiDiscordFill size='20px' style={{ display: "inline" }} />{" "}
            <b>Discord</b>
          </Box>
          <Box flex='1'>
            {loading && <Spinner />}
            {!loading && (
              <>
                {providerData["oidc.discord"].length === 0 && (
                  <Button onClick={() => handleSocialLink("discord")}>
                    Connect Discord
                  </Button>
                )}
                {providerData["oidc.discord"].length > 0 && (
                  <>
                    <Box d='inline-block' mr='2'>
                      {providerData["oidc.discord"].map((p) => {
                        return p.uid;
                      })}
                    </Box>
                    {!isLastProvider && (
                      <Link
                        d='inline-block'
                        onClick={() => handleUnlink("oidc.discord")}
                      >
                        Unlink Discord
                      </Link>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        </Flex> */}

        <Flex minH='40px' borderBottom={`1px solid ${lineColor}`} w='100%'>
          <Box d='inline' flex='0 0 220px'>
            <ImGoogle size='20px' style={{ display: "inline" }} /> <b>Google</b>
          </Box>
          <Box flex='1'>
            {loading && <Spinner />}
            {!loading && (
              <>
                {providerData["google.com"].length === 0 && (
                  <Button onClick={() => handleSocialLink("google")}>
                    Connect Google
                  </Button>
                )}
                {providerData["google.com"].length > 0 && (
                  <>
                    <Box d='inline-block' mr='3'>
                      {providerData["google.com"].map((p) => {
                        return p.email;
                      })}
                    </Box>
                    {!isLastProvider && (
                      <Link onClick={() => handleUnlink("google.com")}>
                        Unlink Google
                      </Link>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        </Flex>

        <Flex minH='40px' borderBottom={`1px solid ${lineColor}`} w='100%'>
          <Box d='inline' flex='0 0 220px'>
            <AiOutlineMail size='20px' style={{ display: "inline" }} />{" "}
            <b>eMail + Password</b>
          </Box>
          <Box flex='1'>
            {loading && <Spinner />}
            {!loading && (
              <>
                {providerData["password"].length === 0 && (
                  <Button onClick={() => handlePassword()}>
                    Connect eMail
                  </Button>
                )}
                {providerData["password"].length > 0 && (
                  <>
                    <Box d='inline-block' mr='3'>
                      {providerData["password"].map((p) => {
                        return p.email;
                      })}
                    </Box>
                    {!isLastProvider && (
                      <Link onClick={() => handleUnlink("password")}>
                        Unlink eMail
                      </Link>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        </Flex>
      </Stack>
    </>
  );
}
