import { Box, Heading, Stack } from "@chakra-ui/layout";
import React from "react";
import Calendar from "react-calendar";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setFilter } from "../eventsActions";

export default function EventFilters({ loading }) {
  const { authenticated } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const { startDate } = useSelector((state) => state.event); // filter,

  return (
    <>
      {authenticated && (
        <Stack>
          <Heading as='h3' size='lg'>
            Filters
          </Heading>
          <Box
            // active={filter === "all"}
            onClick={() => dispatch(setFilter("all"))}
            disabled={loading}
          >
            All Events
          </Box>
          <Box
            // active={filter === "isGoing"}
            onClick={() => dispatch(setFilter("isGoing"))}
            // isDisabled={loading}
          >
            I'm going
          </Box>
          <Box
            // active={filter === "isHost"}
            onClick={() => dispatch(setFilter("isHost"))}
            // isDisabled={loading}
          >
            I'm hosting
          </Box>
        </Stack>
      )}
      <p>Select Date</p>
      <Calendar
        onChange={(date) => dispatch(setFilter("startDate", date))}
        value={startDate || new Date()}
        tileDisabled={() => loading}
      />
    </>
  );
}
