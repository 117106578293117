import { Button } from "@chakra-ui/button";

import { Box, Image, Flex, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../app/common/modals/modalReducer";
import MyAvatar from "../../../app/components/MyAvatar/MyAvatar";
import { countOpenSlots } from "../../../app/common/util/util";
import {
  setLobbyGameInProgress,
  setSelectedInLobby,
  Sleep,
} from "../common/SelectorHelpers";
import boingsfx from "../../../assets/sounds/boingsfx.mp3";
import cheer from "../../../assets/sounds/cheer.mp3";
import useSound from "use-sound";
import bgimg from "../../../assets/gfx/logo_v1_icon_bw.svg";

import ReactCanvasConfetti from "react-canvas-confetti";
import SoundSwitch, {
  soundOn,
} from "../../../app/components/SoundSwitch/SoundSwitch";

export default function Slotmachine() {
  const dispatch = useDispatch();
  const { selectedLobby } = useSelector((state) => state.lobby);
  const { useState } = React;
  const [tempUser, setTempUser] = useState([]);
  const [rolling, setRolling] = useState(false);
  const [rollingDone, setRollingDone] = useState(false);
  const [users, setUsers] = useState(undefined);

  const [playtick] = useSound(boingsfx, { interrupt: true }); // , { stop }
  const [playcheer, { stop }] = useSound(cheer, { interrupt: true }); //

  // Settings
  const maxRolls = 30; // per slot
  const maxSleepTime = 250;

  // const users = selectedLobby.users;
  const numOpenSlots = countOpenSlots(selectedLobby);

  useEffect(() => {
    setLobbyGameInProgress(selectedLobby.lobbyId, "Slotmachine");

    return () => {
      setLobbyGameInProgress(selectedLobby.lobbyId, "");
    };
  }, [selectedLobby.lobbyId]);

  // Initialize
  if (!users && selectedLobby.users) {
    setUsers(selectedLobby.users);
  }
  // Fill tempUsers Array, which holds the random users until committed to
  // Lobby
  if (tempUser.length === 0) {
    let tu = [];
    for (let i = 0; i <= numOpenSlots; i++) {
      tu[i] = {};
    }
    setTempUser(tu);
  }

  // Setting the randoms to actual Lobby
  function handleLockIn() {
    setSelectedInLobby(selectedLobby, tempUser);

    dispatch(closeModal());
  }

  async function roll() {
    setRolling(true);

    // Empty every Slot, just needed for re-rolls
    for (let i = 0; i <= numOpenSlots; i++) {
      tempUser[i] = {};
    }
    setTempUser(tempUser);
    let availableUsers = [...selectedLobby.users].filter(
      (sel) => sel.status !== "not_selected"
    );

    // One Slot after each other
    for (let i = 0; i < numOpenSlots; i++) {
      let selectedUser = undefined;
      // Let's roll x times
      let rolls = maxRolls;
      if (availableUsers.length === 1) rolls = 1;
      for (let rollNum = 1; rollNum <= rolls; rollNum++) {
        let r = Math.floor(Math.random() * availableUsers.length);
        let tu = [...tempUser];
        tu[i] = availableUsers[r];
        tempUser[i] = tu[i];
        selectedUser = availableUsers[r];
        setTempUser(tu);
        let sleepTime = (rollNum / rolls) * maxSleepTime;
        // console.log(!disableSound);
        if (soundOn()) playtick();
        await Sleep(sleepTime);
        // stop();
      }
      // Remove the selected User from available users, so no one can picked twice
      availableUsers = [
        ...availableUsers.filter((elem) => elem.id !== selectedUser.id),
      ];
      setUsers([...availableUsers]);
    }

    if (soundOn()) playcheer();

    setRolling(false);
    setRollingDone(true);
  }

  function handleStopSounds() {
    stop();
  }

  return (
    <>
      <Modal
        isCentered
        size='xl'
        // w='95%'
        // maxH='70vh'
        isOpen={true}
        onClose={() => dispatch(closeModal())}
      >
        <ModalOverlay />
        <ModalContent backgroundColor={"modalBg"} p='0'>
          <ReactCanvasConfetti
            fire={rollingDone}
            angle={90}
            className='canvas'
            colors={[
              "#26ccff",
              "#a25afd",
              "#ff5e7e",
              "#88ff5a",
              "#fcff42",
              "#ffa62d",
              "#ff36ff",
            ]}
            decay={0.8}
            drift={0}
            gravity={1}
            origin={{
              x: 0.5,
              y: 0.5,
            }}
            particleCount={500}
            resize
            scalar={1}
            shapes={["circle", "square"]}
            spread={360}
            startVelocity={45}
            ticks={600}
            useWorker
            zIndex={10}
          />
          <ModalHeader>SlotMachine</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={[1, 5]} position='relative'>
            <Box position='absolute' top='-47px' right='65px'>
              <SoundSwitch cancelSounds={() => handleStopSounds()} />
            </Box>
            <Flex maxW='container.xl' minH='80vh' position='relative' m='auto'>
              <Box m='auto' w='full'>
                <Wrap justify='center'>
                  {Array.from({ length: numOpenSlots }, (_, i) => (
                    <WrapItem textAlign='center'>
                      <VStack w='100%'>
                        {tempUser[i] && (
                          <Box
                            w='124px'
                            h='124px'
                            mb='-15px'
                            position='relative'
                            pt='6px'
                            className='circle'
                            _before={{ borderColor: "primary" }}
                          >
                            <MyAvatar
                              name={tempUser[i].displayName}
                              src={tempUser[i].photoURL}
                              size='xl'
                            />
                          </Box>
                        )}
                        {tempUser[i] && (
                          <Box fontSize='md' textAlign='center' pb='10px'>
                            {tempUser[i].displayName
                              ? tempUser[i].displayName
                              : "?"}
                          </Box>
                        )}
                      </VStack>
                    </WrapItem>
                  ))}
                </Wrap>

                <Box w='100%' textAlign='center' mt='10'>
                  {!rollingDone && (
                    <Button
                      variant='primary'
                      width='200px'
                      className={!rolling ? "roll rolling" : "roll"}
                      onClick={() => roll()}
                      disabled={rolling}
                    >
                      {rolling ? "Rolling..." : "ROLL"}
                    </Button>
                  )}
                  {rollingDone && (
                    <Button
                      variant='primary'
                      width='200px'
                      onClick={() => handleLockIn()}
                    >
                      Continue
                    </Button>
                  )}
                </Box>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter></ModalFooter>
          <Box
            position='absolute'
            overflow='hidden'
            width='100%'
            minHeight='100%'
            height='100%'
            left='0'
            top='0'
            zIndex='1'
            pointerEvents='none'
          >
            <Image
              position='absolute'
              left='20px'
              top='20px'
              src={bgimg}
              height='120vh'
              width='100vw'
              opacity='0.02'
            />
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
}
