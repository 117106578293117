import { Form, Formik } from "formik";
import React from "react";

import * as Yup from "yup";
import { updateUserProfile } from "../../../app/firestore/firestoreService";
import { FormControl } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/button";
import { Box, Text, HStack, Stack } from "@chakra-ui/layout";
import { FormLabel } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/slider";
import MyTextInput from "../../../app/common/form/MyTextInput";

export default function StreamerSettingsMainTab({ currentUserProfile }) {
  const toast = useToast();

  return (
    <Box>
      <Formik
        initialValues={{
          streamDelay: currentUserProfile.streamDelay,
          quizAnswerDuration: currentUserProfile.quizAnswerDuration || 5,
        }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          streamDelay: Yup.string().required(),
          quizAnswerDuration: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            updateUserProfile(values);
            toast({
              title: "Success",
              description: "Data has been updated",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "ERORR",
              description: error.message,
              status: "error",
              isClosable: true,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values, isValid, dirty, setFieldValue }) => (
          <Form>
            <FormControl>
              <Stack w='full'>
                <Box w='full'>
                  <HStack d='flex' pb='10'>
                    <Box flex='1'>
                      <FormLabel htmlFor='name'>Stream Delay</FormLabel>
                      <Box mt='40px'>
                        <Box>{values.streamDelay} seconds</Box>
                        <Slider
                          min={0}
                          max={60}
                          maxWidth={"550px"}
                          step={0.5}
                          aria-label='slider-ex-1'
                          defaultValue={values.streamDelay}
                          onChange={(val) => setFieldValue("streamDelay", val)}
                        >
                          <SliderTrack>
                            <SliderFilledTrack />
                          </SliderTrack>
                          <SliderThumb />
                        </Slider>
                        <Text opacity='0.5'>
                          For some user selection modules (ie the quiz) you can
                          add a delay to sync the actions with your stream
                          delay.
                        </Text>
                      </Box>
                    </Box>
                  </HStack>
                  <Stack
                    d='flex'
                    w='full'
                    direction={["column", "column", "row"]}
                  >
                    <MyTextInput
                      width='60px'
                      flex='1'
                      name='quizAnswerDuration'
                      label='Quiz answer duration in seconds'
                    />
                  </Stack>

                  <Button
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting || !isValid || !dirty}
                    type='submit'
                    variant='primary'
                    ml='230px'
                    minWidth='200px'
                  >
                    Update
                  </Button>
                </Box>
              </Stack>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
