import { mode } from "@chakra-ui/theme-tools";

const baseStyle = (props) => ({
  transitionProperty: "common",
  transitionDuration: "fast",
  transitionTimingFunction: "ease-out",
  cursor: "pointer",
  textDecoration: "none",
  outline: "none",
  color: mode("blue.500", "blue.300")(props),
  _hover: {
    textDecoration: "underline",
  },
  _focus: {
    boxShadow: "outline",
  },
});

export const LinkStyles = {
  baseStyle,
};
