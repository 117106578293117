import { Form, Formik } from "formik";
import React from "react";

import MyTextInput from "../../app/common/form/MyTextInput";
import * as Yup from "yup";
import { updateUserProfile } from "../../app/firestore/firestoreService";
import { FormControl } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/button";
import { Box, Heading, Stack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";

import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { closeModal } from "../../app/common/modals/modalReducer";
import { useDispatch } from "react-redux";

export default function DisplayNameForm({ currentUserProfile, noClose }) {
  const toast = useToast();
  const dispatch = useDispatch();

  return (
    <ModalWrapper noClose={noClose}>
      <Box>
        <Formik
          initialValues={{
            displayName: currentUserProfile.displayName || "",
          }}
          validationSchema={Yup.object({
            displayName: Yup.string().max(20).trim(),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await updateUserProfile(values);
              dispatch(closeModal());
              toast({
                title: "Success",
                description: "Data has been updated",
                status: "success",
                isClosable: true,
              });
            } catch (error) {
              // toast.error(error.message);
              console.log(error.message);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form>
              <FormControl>
                <Stack w='full'>
                  <Heading size='lg'>Welcome!</Heading>
                  <Box>
                    <b>What's your name?</b>{" "}
                    <Box fontSize={"sm"} opacity={"0.5"}>
                      (Will be visible to others)
                    </Box>
                  </Box>
                  <MyTextInput name='displayName' label='' />

                  <Box w='full' mt='30px'>
                    <Button
                      isLoading={isSubmitting}
                      isDisabled={isSubmitting || !isValid}
                      type='submit'
                      w='full'
                      mt='30px'
                      variant='primary'
                    >
                      Save
                    </Button>
                  </Box>
                </Stack>
              </FormControl>
            </Form>
          )}
        </Formik>
      </Box>
    </ModalWrapper>
  );
}
