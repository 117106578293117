import { Box, Center, Text } from "@chakra-ui/layout";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";

export default function PhotoWidgetDropzone({ setFiles }) {
  const dropzoneStyles = {
    border: "dashed 1px #eee",
    borderRadius: "10px",
    textAlign: "center",
  };

  const dropzoneActive = {
    border: "dashed 1px green",
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [setFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box
      {...getRootProps()}
      cursor='pointer'
      p={12}
      style={
        isDragActive ? { ...dropzoneStyles, ...dropzoneActive } : dropzoneStyles
      }
    >
      <input {...getInputProps()} />
      <Center>
        <FiUpload size={30} />
        <Text ml={3}>Drop image here or click to select</Text>
      </Center>
    </Box>
  );
}
