import { Button } from "@chakra-ui/button";
import { Container, Heading } from "@chakra-ui/layout";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Section from "../../components/section/Section";

export default function ErrorComponent({ errormsg }) {
  const { error } = useSelector((state) => state.async);

  return (
    <Container pt='24' maxW={"container.xl"}>
      <Section textAlign='center' p='10'>
        <Heading textAlign='center'>
          {error?.message || errormsg || "Ooops - we have an error"}
        </Heading>
        <Button as={Link} to='/' variant='primary' style={{ marginTop: 20 }}>
          Return to main page
        </Button>
      </Section>
    </Container>
  );
}
