import axios from "axios";

const api = axios.create({
  baseURL:
    "https://sheets.googleapis.com/v4/spreadsheets/1ZKkY7ZVpCQxXG2yobOiqShmMTaLjyQeD4l35yN7ldp0/values/",
});

const apikey = "AIzaSyBjX4aj2aKWkhusU6vhfNM7kjR36XO9PVQ";

export async function getAllQuizQuestions(category) {
  if (!category) return {};
  let r;

  await api
    .get(`${category}?alt=json&key=${apikey}`)
    .then((result) => {
      r = result.data.values;
    })
    .catch(function (error) {
      throw error;
    });

  return r;
}
