import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import {
  signInWithEmail,
  updateUserEmail,
  updateUserPassword,
} from "../../../app/firestore/firebaseService";

import { Button } from "@chakra-ui/button";
import { Stack, Heading, StackDivider, Box } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";

export default function SettingsSecurityTab({ currentUser }) {
  const toast = useToast();

  const hasPassword =
    currentUser?.providerData?.filter((sel) => sel.providerId === "password")
      .length > 0;

  if (!hasPassword)
    return <Box>You're not using eMail + Password for authentification.</Box>;

  return (
    <Box>
      <Stack>
        <StackDivider />
        <Heading variant='formsection'>Change Password</Heading>

        <Formik
          initialValues={{ newPassword1: "", newPassword2: "" }}
          validationSchema={Yup.object({
            password: Yup.string().required("Current Password is required"),
            newPassword1: Yup.string().required("New Password is required"),
            newPassword2: Yup.string().oneOf(
              [Yup.ref("newPassword1", "")],
              "Passwords do not match"
            ),
          })}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            const checkValues = {
              email: currentUser.email,
              password: values.password,
            };
            // console.log(checkValues);
            try {
              await signInWithEmail(checkValues);
              await updateUserPassword(values);
              toast({
                title: "Success",
                description: "Password has been updated",
                status: "success",
                isClosable: true,
              });
            } catch (error) {
              console.log(error);
              setErrors({ auth: error.message });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ errors, isSubmitting, isValid, dirty }) => (
            <Form>
              <MyTextInput
                name='password'
                type='password'
                label='Current Password'
              />

              <MyTextInput
                name='newPassword1'
                type='password'
                label='New Password'
              />
              <MyTextInput
                name='newPassword2'
                type='password'
                label='Confirm Password'
              />
              {errors.auth && (
                <Box textColor='red.300' p='3'>
                  {errors.auth}
                </Box>
              )}

              <Button
                style={{ display: "block" }}
                type='submit'
                isDisabled={!isValid || isSubmitting || !dirty}
                isLoading={isSubmitting}
                variant='primary'
                mt='3'
              >
                Update Password
              </Button>
            </Form>
          )}
        </Formik>

        <Heading pt='50px' d='block' variant='formsection'>
          Change eMail
        </Heading>
        <Formik
          initialValues={{ newPassword1: "", newPassword2: "" }}
          validationSchema={Yup.object({
            password: Yup.string().required("Current Password is required"),
            email: Yup.string().required().email(),
          })}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            const checkValues = {
              email: currentUser.email,
              password: values.password,
            };

            try {
              await signInWithEmail(checkValues);
              await updateUserEmail(values);
              toast({
                title: "Success",
                description: "eMail has been updated",
                status: "success",
                isClosable: true,
              });
            } catch (error) {
              console.log(error);
              setErrors({ auth: error.message });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ errors, isSubmitting, isValid, dirty }) => (
            <Form>
              <MyTextInput
                name='currentpassword'
                type='password'
                label='Current Password'
              />

              <MyTextInput name='email' type='email' label='New eMail' />
              {errors.auth && (
                <Box textColor='red.300' p='3'>
                  {errors.auth}
                </Box>
              )}
              <Button
                style={{ display: "block" }}
                type='submit'
                isDisabled={!isValid || isSubmitting || !dirty}
                isLoading={isSubmitting}
                variant='primary'
                mt='3'
              >
                Update eMail
              </Button>
            </Form>
          )}
        </Formik>
      </Stack>
    </Box>
  );
}
