import React, { useEffect, useState } from "react"; // useEffect when mounts and unmoints
import EventList from "./EventList";
// import { sampleData } from "../../../app/api/sampleData";
import { useDispatch, useSelector } from "react-redux";
import EventListItemPlaceholder from "./EventListItemPlaceholder";
import EventFilters from "./EventFilters";
import { fetchEvents } from "../eventsActions";
import EventFeed from "./EventFeed";
import { RETAIN_STATE } from "../eventConstants";
import { Box, Flex, Container } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/react";

export default function EventDashboard() {
  // const [events, setEvents] = useState(sampleData);
  const limit = 2;
  const dispatch = useDispatch();
  const { events, moreEvents, filter, startDate, lastVisible, retainState } =
    useSelector((state) => state.event);
  const { loading } = useSelector((state) => state.async);
  const { authenticated } = useSelector((state) => state.auth);
  const [loadingInitial, setLoadingInitial] = useState(false);

  useEffect(() => {
    if (retainState) return;
    setLoadingInitial(true);
    dispatch(fetchEvents(filter, startDate, limit)).then(() => {
      setLoadingInitial(false);
    });
    return () => {
      dispatch({ type: RETAIN_STATE });
    };
  }, [dispatch, filter, startDate, retainState]);

  function handleFetchNextEvents() {
    dispatch(fetchEvents(filter, startDate, limit, lastVisible));
  }

  // useFirestoreCollection({
  //   query: () => listenToEventsFromFirestore(predicate),
  //   data: (events) => dispatch(listenToEvents(events)),
  //   deps: [dispatch, predicate],
  // });

  // useEffect(() => {
  //   dispatch(asyncActionStart());
  //   const unsubscribe = getEventsFromFirestore({
  //     next: (snapshot) => {
  //       dispatch(
  //         listenToEvents(
  //           snapshot.docs.map((docSnapshot) => dataFromSnapshot(docSnapshot))
  //         )
  //       );
  //       dispatch(asyncActionFinish());
  //     },
  //     error: (error) => dispatch(asyncActionError(error)),
  //     complete: () => console.log("you will never see this message"),
  //   });
  //   return unsubscribe; // called when unmounted
  // }, [dispatch]);

  // if (loading) return <LoadingComponent />;

  // function handleCreateEvent(event) {
  //   setEvents([...events, event]);
  // }
  //
  // function handleUpdateEvent(updatedEvent) {
  //   setEvents(
  //     events.map((evt) => (evt.id === updatedEvent.id ? updatedEvent : evt))
  //   );
  //   selectEvent(null);
  // }

  // function handleDeleteEvent(eventID) {
  //   // setEvents(events.filter((evt) => evt.id !== eventID));
  // }

  return (
    <Container pt='20' maxW='container.xl'>
      <Flex>
        <Box flex='1'>
          {loadingInitial && (
            <>
              <EventListItemPlaceholder />
              <EventListItemPlaceholder />
            </>
          )}
          <EventList
            events={events}
            getNextEvents={handleFetchNextEvents}
            loading={loading}
            moreEvents={moreEvents}
          />
        </Box>
        <Box w='300px' p='3'>
          {authenticated && <EventFeed />}
          <EventFilters loading={loading} />
          {loading && <Spinner />}
        </Box>
      </Flex>
    </Container>
  );
}
