import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { clearSelectedEvent, listenToSelectedEvent } from "../eventsActions";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import { categoryData } from "../../../app/api/categoryData";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyDateInput from "../../../app/common/form/MyDateInput";
import {
  addEventToFirestore,
  cancelEventToggle,
  listenToEventFromFirestore,
  updateEventInFirestore,
} from "../../../app/firestore/firestoreService";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import Section from "../../../app/components/section/Section";
import { Box, Container, Heading } from "@chakra-ui/layout";
import { Button, ButtonGroup } from "@chakra-ui/button";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";

export default function EventForm({ match, history, location }) {
  const dispatch = useDispatch();
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { loading, error } = useSelector((state) => state.async);
  const { selectedEvent } = useSelector((state) => state.event);

  const cancelRef = React.useRef();

  useEffect(() => {
    if (location.pathname !== "/createEvent") return;
    dispatch(clearSelectedEvent());
  }, [dispatch, location.pathname]);

  const initialValues = selectedEvent ?? {
    title: "",
    category: "",
    description: "",
    city: "",
    venue: "",
    date: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("You must provide a title"),
    category: Yup.string().required("You must provide a category"),
    description: Yup.string().required(),
    city: Yup.string().required(),
    venue: Yup.string().required(),
    date: Yup.string().required(),
  });

  async function handleCancelToggle(event) {
    setConfirmOpen(false);
    setLoadingCancel(true);
    try {
      await cancelEventToggle(event);
      setLoadingCancel(false);
      history.push(`/events/${match.params.id}`);
    } catch (error) {
      setLoadingCancel(true);
      toast.error(error.message);
    }
  }

  // HOOKS ARE ALWAYS EXECUTED, cant be put in IF
  useFirestoreDoc({
    // shouldExecute: !!match.params.id, //casting id in boolean
    shouldExecute:
      match.params.id !== selectedEvent?.id &&
      location.pathname !== "/createEvent", //casting id in boolean
    query: () => listenToEventFromFirestore(match.params.id),
    data: (event) => dispatch(listenToSelectedEvent(event)),
    deps: [match.params.id, dispatch],
  });

  if (loading) return <LoadingComponent content='Loading event...' />;

  if (error) return <Redirect to='/error' />;

  return (
    <Container maxW='container.xl'>
      <Section clearing p='10'>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              if (selectedEvent) {
                await updateEventInFirestore(values);
                history.push(`/events/${match.params.id}`);
              } else {
                await addEventToFirestore(values).then((response) => {
                  history.push(`/events/${response.id}`);
                });
              }
              setSubmitting(false);
            } catch (error) {
              toast.error(error.message);
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, dirty, isValid }) => (
            <Box maxW='4xl' pl='20%'>
              <Form>
                <Heading variant='formsection'>Event Details</Heading>
                <MyTextInput name='title' label='Event title' />
                <MySelectInput
                  name='category'
                  label='Category'
                  options={categoryData}
                />
                <MyTextArea name='description' label='Description' rows='3' />
                <Heading variant='formsection'>Event Location Details</Heading>
                <MyTextInput name='city' label='City' />
                <MyTextInput name='venue' label='Event venue' />
                <MyDateInput
                  name='date'
                  label='Event date'
                  placeholderText='Event date'
                  timeFormat='HH:mm'
                  showTimeSelect
                  timeCaption='time'
                  dateFormat='MMMM d, yyyy h:mm a'
                  autoComplete='off'
                />

                <ButtonGroup mb='10' mt='10' w='full' d='block'>
                  <Button
                    isLoading={isSubmitting}
                    isDisabled={!isValid || !dirty || isSubmitting}
                    variant='primary'
                    type='submit'
                    float='left'
                  >
                    Submit
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    as={Link}
                    to={`/events/${match.params.id}`}
                    // onClick={() => setFormOpen(false)}
                    type='submit'
                    float='left'
                  >
                    Cancel
                  </Button>

                  {selectedEvent && (
                    <Button
                      isLoading={loadingCancel}
                      type='button'
                      float='right'
                      color={selectedEvent.isCancelled ? "green" : "red"}
                      onClick={() => setConfirmOpen(true)}
                    >
                      {selectedEvent.isCancelled
                        ? "Reactivate event"
                        : "Cancel Event"}
                    </Button>
                  )}
                </ButtonGroup>
              </Form>
            </Box>
          )}
        </Formik>

        <AlertDialog
          isOpen={confirmOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => setConfirmOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Cancel Event
              </AlertDialogHeader>

              <AlertDialogBody>
                {selectedEvent?.isCancelled
                  ? "This will reactivate the event - are you sure?"
                  : "This will cancel the event - are your sure?"}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={() => setConfirmOpen(false)}>
                  Cancel
                </Button>
                <Button
                  colorScheme='red'
                  onClick={() => handleCancelToggle(selectedEvent)}
                  ml={3}
                >
                  Confirm
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Section>
    </Container>
  );
}
