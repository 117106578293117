import { firebaseObjectToArray } from "../../app/firestore/firebaseService";
import {
  CREATE_LOBBY,
  DELETE_LOBBY,
  UPDATE_LOBBY,
  LISTEN_TO_SELECTED_LOBBY,
  CLEAR_SELECTED_LOBBY,
} from "./lobbyConstants";

export function listenToSelectedLobby(lobby, lobbyId) {
  const newUsers = firebaseObjectToArray(lobby.users);
  const newLobby = {
    ...lobby,
    lobbyId: lobbyId,
    users: newUsers,
  };
  return {
    type: LISTEN_TO_SELECTED_LOBBY,
    payload: newLobby,
  };
}

export function clearSelectedLobby() {
  return {
    type: CLEAR_SELECTED_LOBBY,
  };
}

export function createLobby(lobby) {
  return {
    type: CREATE_LOBBY,
    payload: lobby,
  };
}

export function updateLobby(lobby) {
  return {
    type: UPDATE_LOBBY,
    payload: lobby,
  };
}

export function deleteLobby(lobbyId) {
  return {
    type: DELETE_LOBBY,
    payload: lobbyId,
  };
}
