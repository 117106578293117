import { Box, Button, Heading, HStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { countOpenSlots } from "../../../app/common/util/util";
import MyAvatar from "../../../app/components/MyAvatar/MyAvatar";
import {
  removeLobbyQuizInFirebase,
  updateLobbyUsersBulkStatusInFirebase,
} from "../../../app/firestore/firebaseService";
import {
  setLobbyGameInProgress,
  setSelectedInLobby,
} from "../common/SelectorHelpers";

export default function QuizFininished({ selectedLobby, lobbyId, quizData }) {
  const dispatch = useDispatch();
  const [quizResults, setQuizResults] = useState(undefined);
  const [winnersAndLosers, setWinnersAndLosers] = useState(undefined);

  useEffect(() => {
    function calcResults(quizData) {
      let results = [];

      for (let index = 0; index < selectedLobby.users.length; index++) {
        const element = selectedLobby.users[index];
        const userId = selectedLobby.users[index].userId;
        if (userId && element.status !== "not_selected") {
          results[userId] = {
            userId: userId,
            userInfo: element,
            points: 0,
          };
        }
      }

      if (quizData !== null && quizData.answers) {
        for (const [key, question] of Object.entries(quizData?.questions)) {
          quizData.questions[key].userAnswers = quizData.answers[question.id];
          if (quizData?.answers[question.id]) {
            for (const [k, v] of Object.entries(
              quizData?.answers[question.id]
            )) {
              if (question.correctAnswer === v.answer) {
                if (results[k]) results[k].points = results[k].points + 1;
              }
            }
          }
        }
      }

      let r = [];
      // eslint-disable-next-line
      for (const [k, v] of Object.entries(results)) {
        r.push(v);
      }

      r.sort((a, b) => (a.points < b.points ? 1 : -1));

      const winners = [];
      const losers = [];
      const maxUsers = 1;
      let numWinners = 0;
      let winnerPoints = 0;
      r.forEach((element) => {
        // Users with same points will also be winners, even if we have too many this way.
        if (numWinners < maxUsers || winnerPoints === element.points) {
          winners.push(element.userInfo);
          numWinners = numWinners + 1;
          winnerPoints = element.points;
        } else {
          losers.push(element.userInfo);
        }
      });

      setQuizResults(r);
      setWinnersAndLosers({ winners: winners, losers: losers });
    }

    calcResults(quizData);
  }, [quizData, selectedLobby]);

  function handleFinallyEndQuiz() {
    const numOpenSlots = countOpenSlots(selectedLobby);

    console.log(winnersAndLosers.losers);
    if (winnersAndLosers.winners?.length <= numOpenSlots) {
      // We have less or equal needed players, so lets place them in the team
      setSelectedInLobby(selectedLobby, winnersAndLosers.winners);
    } else if (winnersAndLosers.winners?.length === 0) {
      // No Winners at all, do nothing...?
    } else {
      // whoops, we have too many, so everyone else will be not_selected
      updateLobbyUsersBulkStatusInFirebase(
        selectedLobby.lobbyId,
        winnersAndLosers.losers,
        "not_selected"
      );
    }

    dispatch(closeModal());
    setLobbyGameInProgress(selectedLobby.lobbyId, "");
    removeLobbyQuizInFirebase(lobbyId);
  }

  return (
    <Box minHeight='550px'>
      <Heading>Quiz Finished!</Heading>
      <Button
        mt='20px'
        variant={"primary"}
        onClick={() => handleFinallyEndQuiz()}
      >
        Proceed
      </Button>
      <Heading mt='30px' size='lg'>
        Results
      </Heading>
      <Box mt='20px' borderBottom='1px solid rgba(255,255,255,0.1)'>
        {quizResults?.map((e) => (
          <HStack
            borderTop='1px solid rgba(255,255,255,0.1)'
            key={e.userId}
            p='5px'
            color={
              winnersAndLosers.winners.filter(
                (elem) => elem?.userId === e?.userId
              ).length
                ? "white"
                : "rgba(255,255,255,0.3)"
            }
          >
            <Box w='300px' height='36px' lineHeight={"36px"}>
              <MyAvatar
                size='sm'
                mr='3px'
                src={e.userInfo.photoURL}
                name={e.userInfo.displayName}
              />{" "}
              {e.userInfo.displayName}
            </Box>
            <Box>
              <b>{e.points}</b> points
            </Box>
          </HStack>
        ))}
      </Box>
    </Box>
  );
}
