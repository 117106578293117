import React from "react";
import {
  Modal,
  Spinner,
  ModalBody,
  ModalContent,
  HStack,
} from "@chakra-ui/react";

export default function LoadingComponent({ content = "Loading ..." }) {
  return (
    <>
      <Modal isCentered size='lg' isOpen={true}>
        {/* <ModalOverlay /> */}
        <ModalContent
          bg='transparent'
          boxShadow='none'
          borderRadius='20px'
          mt={[50, 50]}
        >
          <ModalBody>
            <HStack>
              <Spinner />
              <span>{content}</span>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
