import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export default function ProgressbarTime({
  duration,
  inverted,
  height = "3px",
  ...rest
}) {
  const [percent, setPercent] = useState(0);
  const intervall = (duration * 1000) / 100;

  useEffect(() => {
    if (percent < 100) {
      setTimeout(() => {
        setPercent(percent + 1);
      }, intervall);
    }
  }, [intervall, percent]);

  return (
    <Box
      position='relative'
      height={height}
      display='block'
      width='100%'
      m='0'
      p='0'
      bg={inverted ? "gray.600" : "gray.800"}
      {...rest}
      borderRadius={height}
    >
      <Box
        // borderRadius={height}
        display='block'
        bg={inverted ? "gray.700" : "gray.600"}
        width={`${percent}%`}
        height={height}
        position='relative'
        transition={`${duration / 100}s linear`}
      ></Box>
    </Box>
  );
}
