import { Form, Formik } from "formik";
import React from "react";
// import ModalWrapper from "../../app/common/modals/ModalWrapper";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { Button } from "@chakra-ui/button";
import { useDispatch } from "react-redux";
// import { closeModal } from "../../app/common/modals/modalReducer";
import { socialLinkPassword } from "../../app/firestore/firebaseService";

import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { HStack } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { closeModal } from "../../app/common/modals/modalReducer";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { verifyAuth } from "./authActions";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../../app/async/asyncReducer";

export default function EmailPasswordForm() {
  const toast = useToast();
  const dispatch = useDispatch();

  function handleCancel() {
    dispatch(closeModal());
    dispatch(asyncActionFinish());
  }

  return (
    <ModalWrapper size='md' header='Connect eMail + Password'>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={Yup.object({
          email: Yup.string().required().email(),
          password: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            dispatch(asyncActionStart());
            dispatch(closeModal());
            socialLinkPassword(values)
              .then(() => {
                toast({
                  title: "Success",
                  description:
                    "eMail and Password has been linked to your account.",
                  status: "success",
                  isClosable: true,
                });
                setSubmitting(false);
                dispatch(verifyAuth());
                dispatch(asyncActionFinish());
              })
              .catch((error) => {
                toast({
                  title: "Error",
                  description: error.message,
                  status: "error",
                  isClosable: true,
                });
                dispatch(closeModal());
                setSubmitting(false);
                dispatch(asyncActionError(error));
              });
          } catch (error) {
            setSubmitting(false);
            dispatch(asyncActionError(error));
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form>
            <FormControl>
              <Stack spacing={5}>
                <MyTextInput
                  name='email'
                  id='email'
                  // placeholder='Email Adress'
                  label='eMail'
                />
                <MyTextInput
                  name='password'
                  id='password'
                  // placeholder='Password'
                  type='password'
                  label='Password'
                />
                {errors.auth && (
                  <FormLabel color='red' style={{ marginBottom: 10 }}>
                    {errors.auth}
                  </FormLabel>
                )}

                <HStack>
                  <Button
                    isLoading={isSubmitting}
                    isDisabled={!isValid || !dirty || isSubmitting ? 1 : 0}
                    type='submit'
                    id='id_submit'
                    variant='primary'
                    w={400}
                  >
                    Send
                  </Button>
                  <Button onClick={handleCancel} variant='outline' w={100}>
                    Cancel
                  </Button>
                </HStack>
              </Stack>
            </FormControl>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
