import React, { Suspense } from "react";
import EventDashboard from "../../features/events/eventDashboard/EventDashboard";
import NavBar from "../../features/nav/NavBar";
import HomePage from "../../features/home/HomePage";
import Sandbox from "../../features/sandbox/Sandbox";
import ModalManager from "../common/modals/ModalManager";

import { Route, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useColorMode, useColorModeValue } from "@chakra-ui/color-mode";
import { Box } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";

import ErrorComponent from "../common/errors/ErrorCompentent";

import bgimg from "../../assets/gfx/logo_v1_icon_bw.svg";
import StreamerSettingsQuizImportPreview from "../../features/profiles/streamerSettings/StreamerSettingsQuizImportPreview";
import CookieConsent from "react-cookie-consent";

import LoadingComponent from "./LoadingComponent";
import PrivateRoute from "./PrivateRoute";

import ProfilePage from "../../features/profiles/profilePage/ProfilePage";
import LoginPage from "../../features/auth/LoginPage";
import RegisterPage from "../../features/auth/RegisterPage";
import EventDetailedPage from "../../features/events/eventDetailed/EventDetailedPage";
import EventForm from "../../features/events/eventForm/EventForm";
import SettingsPage from "../../features/profiles/settings/SettingsPage";
import LobbyForm from "../../features/lobby/lobbyForm/LobbyForm";
import StreamerUrl from "../../features/profiles/streamerPage/StreamerUrl";
import StreamerSettingsPage from "../../features/profiles/streamerSettings/StreamerSettingsPage";
import LogoutPage from "../../features/auth/LogoutPage";
import LegalText from "../../features/legal/LegalText";
import Footer from "./Footer";
import RecoverPasswordPage from "../../features/auth/RecoverPasswordPage";
import About from "../../features/about/About";

// const About = lazy(() => import("../../features/about/About"));

function App() {
  const { key } = useLocation();
  const { initialized } = useSelector((state) => state.async);
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = useColorModeValue("gray.50", "gray.800");
  if (colorMode === "light") toggleColorMode();

  if (!initialized) return <LoadingComponent content='Loading app..' />;

  return (
    <Box bg={bg} backgroundSize='cover'>
      <Box
        position='absolute'
        overflow='hidden'
        width='calc(100vw - (100vw - 100%))'
        minHeight='100vh'
        height='100%'
        left='0'
        top='0'
        zIndex='1'
        pointerEvents='none'
      >
        <Image
          position='absolute'
          left='30vw'
          top='80px'
          src={bgimg}
          width='100vw'
          opacity='0.05'
        />
      </Box>

      <Box zIndex='1' position='relative'>
        <ModalManager />
        <ToastContainer
          position='bottom-center'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {/* Pages WITHOUT Navbar */}
            <Route exact path='/'>
              <HomePage />
            </Route>
            <Route exact path='/login'>
              <LoginPage />
            </Route>
            <Route exact path='/logout'>
              <LogoutPage />
            </Route>
            <Route exact path='/register'>
              <RegisterPage />
            </Route>
            <Route exact path='/recoverpassword'>
              <RecoverPasswordPage />
            </Route>
            {/* Pages with Navbar */}
            <Route>
              <NavBar />
              <Switch>
                <Route exact path='/about/:section?'>
                  <About />
                </Route>
                <Route exact path='/help/:section?'>
                  <About />
                </Route>
                <Route exact path='/events'>
                  <EventDashboard />
                </Route>
                <Route exact path='/terms-of-use'>
                  <LegalText module='terms-of-use' />
                </Route>
                <Route exact path='/imprint'>
                  <LegalText module='imprint' />
                </Route>
                <Route exact path='/sandbox'>
                  <Sandbox />
                </Route>
                <Route path='/profile/:url?/:id'>
                  <ProfilePage />
                </Route>
                <Route path='/events/:id' component={EventDetailedPage} />
                <PrivateRoute
                  path={["/createEvent", "/manage/:id"]}
                  component={EventForm}
                  key={key}
                />
                <Route
                  path={["/createLobby", "/editLobby/:id"]}
                  component={LobbyForm}
                  key={key}
                />
                <PrivateRoute
                  path='/settings/:tabpage?/:action?'
                  comp={SettingsPage}
                />
                <PrivateRoute
                  path='/streamersettings/:tabpage?/:action?'
                  comp={StreamerSettingsPage}
                  key={key}
                />
                <PrivateRoute
                  path='/previewimportquestions'
                  comp={StreamerSettingsQuizImportPreview}
                  key={key}
                />

                {/* <StreamerSettingsPage />
            </PrivateRoute> */}
                <Route path='/:url/:lobbyId?' component={StreamerUrl} />
                <Route>
                  {/* Never reached, 404 not found page called in StreamerPage  */}
                  <ErrorComponent />
                </Route>
              </Switch>
            </Route>
          </Switch>
        </Suspense>

        <Footer />
        <CookieConsent
          location='bottom'
          buttonText='Ok!'
          cookieName='cookieConsent'
          style={{ background: "#111" }}
          // buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </Box>
      {/* 
    <Route exact path='/' component={HomePage} />
      <Route exact path='/login' component={LoginPage} />
      <Route
        path={"/(.+)"}
        render={() => (
          <>
            <NavBar />
            <MainLayout>
              <Route exact path='/events' component={EventDashboard} />
              <Route exact path='/sandbox' component={Sandbox} />
              <Route path='/events/:id' component={EventDetailedPage} />
              
              
              <PrivateRoute path='/profile/:id' component={ProfilePage} />
              <Route path='/error' component={ErrorComponent} />
            </MainLayout>
          </>
        )}
      /> */}
    </Box>
  );
}

export default App;
