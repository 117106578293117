import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncActionFinish,
  asyncActionStart,
} from "../../../app/async/asyncReducer";
import { Button } from "@chakra-ui/button";
import {
  Box,
  Spacer,
  Heading,
  HStack,
  SimpleGrid,
  Text,
} from "@chakra-ui/layout";
import {
  endLobbyQuizInFirebase,
  setLobbyQuizUsersCanAnswerFirebase,
  updateLobbyQuizInFirebase,
} from "../../../app/firestore/firebaseService";
import ProgressbarTime from "../../../app/components/Progressbar/ProgressbarTime";

export default function QuizRunningForm({ lobbyId, selectedLobby, quizData }) {
  const { currentUserProfile } = useSelector((state) => state.profile);
  // const [quizitems, setQuizitems] = useState(undefined);
  // const toast = useToast();
  const delay = currentUserProfile.streamDelay || 3;
  const timeToAnswer = currentUserProfile.quizAnswerDuration || 5; // seconds
  const dispatch = useDispatch();
  const [isWaitingForNextQuestion, setIsWaitingForNextQuestion] =
    useState(undefined);
  const [usersCurrentlyAnswering, setUsersCurrentlyAnswering] = useState(false);
  const correctAnswer =
    quizData?.questions[selectedLobby.quiz.currentQuestion.index]
      ?.correctAnswer;

  const question =
    isWaitingForNextQuestion === undefined
      ? selectedLobby.quiz.currentQuestion
      : isWaitingForNextQuestion;
  const usersCanAnswer = question.usersCanAnswer;

  const isLastQuestion = question.index + 1 === quizData?.questions.length;

  const numUsersInLobby = selectedLobby.users.length;
  let answers;
  let totalAnswers = 0;
  if (quizData?.answers) answers = quizData?.answers[question.id];
  if (answers) {
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(answers)) {
      totalAnswers = totalAnswers + 1;
    }
  }

  function seeResults() {
    endLobbyQuizInFirebase(lobbyId);
  }

  function handleNextQuestion() {
    // prepareNextQuestion(0);
    const newCurrentQuestion = {
      index: question.index + 1,
      maxIndex: quizData.questions.length - 1,
      usersCanAnswer: true,
      ...quizData.questions[question.index + 1],
    };

    setIsWaitingForNextQuestion(newCurrentQuestion);

    setTimeout(() => {
      dispatch(asyncActionStart());

      updateLobbyQuizInFirebase({
        lobbyId: lobbyId,
        questions: quizData.questions,
        index: question.index + 1,
        currentQuestion: newCurrentQuestion,
      });
      setIsWaitingForNextQuestion(undefined);
      setUsersCurrentlyAnswering(true);
      setTimeout(() => {
        setUsersCurrentlyAnswering(false);
        setLobbyQuizUsersCanAnswerFirebase({
          lobbyId: lobbyId,
          usersCanAnswer: false,
        });
      }, timeToAnswer * 1000);
      dispatch(asyncActionFinish());
    }, delay * 1000);
  }

  function AnswerBox({ answer, children }) {
    let thisAnswer = 0;

    if (answers) {
      // Object.keys(answers).map((value) => {
      //   totalAnswers = totalAnswers + 1;
      //   if (value.answer === answer) thisAnswer = thisAnswer + 1;
      //   return true;
      // });

      // eslint-disable-next-line
      for (const [key, value] of Object.entries(answers)) {
        if (value.answer === answer) thisAnswer = thisAnswer + 1;
      }
    }

    return (
      <Box
        height='150px'
        bg={
          usersCanAnswer === false && correctAnswer === answer
            ? "teal.300"
            : "gray.500"
        }
        textAlign='center'
        m='auto'
        d='inline-block'
        p='0px'
        w='100%'
        borderRadius='10px'
        position='relative'
        // borderWidth='10px'
      >
        <Box
          m='auto'
          position='absolute'
          top='50%'
          left='50%'
          transform='translate(-50%, -50%)'
          color={
            usersCanAnswer === false && correctAnswer === answer
              ? "black"
              : "white"
          }
          fontSize='lg'
        >
          {children}
          {!usersCanAnswer && totalAnswers > 0 && (
            <Box mt='10px' fontWeight='bold'>
              {Math.round((thisAnswer / totalAnswers) * 100)}%
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box minHeight='595px'>
      <Text>
        {question.index === -1 ? "Quiz is about to start!" : "Quiz in progress"}
      </Text>

      <HStack mt='10px'>
        {!isLastQuestion && (
          <Button
            isDisabled={isWaitingForNextQuestion || usersCurrentlyAnswering}
            onClick={() => handleNextQuestion()}
            variant='primary'
          >
            {question.index === -1
              ? "Start with first question!"
              : "Next question"}
          </Button>
        )}
        <Spacer />
        <Button
          variant={isLastQuestion ? "primary" : "solid"}
          onClick={() => seeResults()}
        >
          End Quiz
        </Button>
      </HStack>
      {question.index >= 0 && (
        <>
          <Heading mt='30px' size='xl' minHeight='86px'>
            {question.question}
          </Heading>
          <Box my='10px' textAlign={"right"}>
            {totalAnswers} / {numUsersInLobby} users answered
          </Box>
          <SimpleGrid columns={2} spacing={"20px"}>
            <AnswerBox answer={1}>{question.answer1}</AnswerBox>
            <AnswerBox answer={2}>{question.answer2}</AnswerBox>
            <AnswerBox answer={3}>{question.answer3}</AnswerBox>
            <AnswerBox answer={4}>{question.answer4}</AnswerBox>
          </SimpleGrid>
        </>
      )}
      <Box height='36px' py='20px'>
        {isWaitingForNextQuestion !== undefined && (
          <>
            <ProgressbarTime duration={delay} />
            <Text fontSize={"xs"} opacity='0.5'>
              Questions will be send with a delay of {delay} seconds to
              compensate the stream delay.{" "}
            </Text>
          </>
        )}
        {usersCurrentlyAnswering && (
          <>
            <ProgressbarTime duration={timeToAnswer} />
            <Text fontSize={"xs"} opacity='0.5'>
              Users have {timeToAnswer} seconds to answer the question.
            </Text>
          </>
        )}
      </Box>
    </Box>
  );
}
