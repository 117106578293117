import { Box, Stack } from "@chakra-ui/layout";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import ProfileHeader from "../profilePage/ProfileHeader";
import SettingsAboutTab from "./SettingsAboutTab";
import SettingsSecurityTab from "./SettingsSecurityTab";
import { useColorModeValue } from "@chakra-ui/color-mode";
import SettingsConnectionsTab from "./SettingsConnectionsTab";
import Vcontainer from "../../../app/components/Vcontainer/Vcontainer";
import SettingsAccountsTab from "./SettingsAccountsTab";
import { useHistory } from "react-router";
import { useEffect } from "react";

export default function SettingsPage({ match }) {
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { currentUser } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.async);
  const history = useHistory();
  const bg = useColorModeValue("gray.200", "gray.900");
  const [tabIndex, setTabIndex] = React.useState(0);
  const tabpage = match.params.tabpage || "about";
  const tabs = useMemo(
    () => ["about", "passwordemail", "sociallogins", "accounts"],
    []
  );

  useEffect(() => {
    setTabIndex(parseInt(tabs.indexOf(tabpage)));
  }, [tabs, match, tabpage]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    history.push(`/settings/${tabs[index]}`);
  };

  if ((loading && !currentUserProfile) || (!currentUserProfile && !error))
    return <LoadingComponent content='Loading Profile...' />;

  return (
    <Vcontainer>
      <ProfileHeader profile={currentUserProfile} isCurrentUser={true} />
      <Tabs
        variant='line'
        orientation='horizontal'
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <TabList flex='0 0 200px' m='3'>
          <Tab fontSize={["xs", "md"]} _focus={{ boxShadow: "none" }}>
            About
          </Tab>
          <Tab fontSize={["xs", "md"]} _focus={{ boxShadow: "none" }}>
            Security
          </Tab>
          <Tab fontSize={["xs", "md"]} _focus={{ boxShadow: "none" }}>
            Social Logins
          </Tab>
          <Tab fontSize={["xs", "md"]} _focus={{ boxShadow: "none" }}>
            Accounts
          </Tab>
        </TabList>
        <Box
          bg={bg}
          p='5'
          className='skew'
          borderRadius='20'
          minH='750px'
          flex='1'
        >
          <Box className='unskew'>
            <TabPanels>
              <TabPanel>
                <SettingsAboutTab currentUserProfile={currentUserProfile} />
              </TabPanel>
              <TabPanel>
                <SettingsSecurityTab currentUser={currentUser} />
              </TabPanel>
              <TabPanel>
                <SettingsConnectionsTab currentUser={currentUser} />
              </TabPanel>
              <TabPanel>
                <SettingsAccountsTab
                  match={match}
                  currentUserProfile={currentUserProfile}
                />
              </TabPanel>
            </TabPanels>
          </Box>
        </Box>
      </Tabs>

      <Stack></Stack>
    </Vcontainer>
  );
}
