import { Avatar } from "@chakra-ui/avatar";
import React from "react";
// import styled, { css } from "styled-components";

export default function MyAvatar({
  children,
  src,
  size = "sm",
  name = "",
  ignoreFallback = "true",
  ...props
}) {
  if (name === "") {
    props["bg"] = "transparent";
  }

  return (
    <Avatar
      {...props}
      position='relative'
      // ignoreFallback={ignoreFallback}
      size={size}
      src={src}
      name={name}
      // src={`https://i.pravatar.cc/150?u=${src}`}
    />
  );
}

// const sizes = {
//   xs: 30,
//   sm: 50,
//   md: 80,
//   lg: 100,
//   xl: 120,
// };

// const width = sizes[size];
// const margin = width * (35 / 120);
// const height = width * (174 / 300);
// const rotatorWidth = width * (220 / 190);
// const rotatorWidthTranslate = rotatorWidth / 2;

// const face = css`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   background: inherit;
//   z-index: -1;
//   /* Keeps borders smooth in webkit */
//   backface-visibility: hidden;

//   &:before {
//     content: "";
//     position: absolute;
//     width: ${rotatorWidth}px;
//     height: ${rotatorWidth}px;
//     background: inherit;
//   }
// `;

// const Face1 = styled.div`
//   ${face}
//   transform: rotate(60deg);
//   &:before {
//     left: 0;
//     transform-origin: left top;
//     transform: rotate(-60deg) translate(-${rotatorWidthTranslate}px, 0);
//   }
//   // border-right: 2px solid #000;
//   // border-left: 2px solid #000;
// `;

// const Face2 = styled.div`
//   ${face}
//   // border-left: 2px solid #000;
//   // border-right: 2px solid #000;
//   transform: rotate(-60deg);
//   &:before {
//     right: 0;
//     transform-origin: right top;
//     transform: rotate(60deg) translate(${rotatorWidthTranslate}px, 0);
//   }
// `;

// const Hexagon = styled.div`
//   position: relative;
//   margin: ${margin}px 0;
//   width: ${width}px;
//   height: ${height}px;
//   // border-left: 2px solid #000;
//   // border-right: 2px solid #000;

//   background-color: #444;
//   background-image: url(${src});
//   background-size: ${width * 1.1}px ${width * 1.1}px;
//   background-position: center center;
//   z-index: 1;

//   /* Text styling */
//   line-height: 110px;
//   text-align: center;
//   font-family: sans-serif;
//   font-size: 15px;
//   font-weight: bold;
//   letter-spacing: 6px;
//   color: #fff;
//   color: rgba(255, 255, 255, 0.5);
//   cursor: pointer;
// `;

// return (
//   <Hexagon>
//     {children}
//     <Face1></Face1>
//     <Face2></Face2>
//   </Hexagon>
// );
