import { Heading } from "@chakra-ui/react";
import React from "react";

export default function MySubHeading({ children }) {
  return (
    <Heading size='md' mt='40px'>
      {children}
    </Heading>
  );
}
