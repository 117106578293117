import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box, Flex, Heading, Stack } from "@chakra-ui/layout";
import React from "react";
import RecoverPasswordForm from "./RecoverPasswordForm";

export default function RecoverPasswordPage({ history }) {
  return (
    <>
      <Flex minH={"100vh"} align={"center"} justify={"center"}>
        <Stack spacing='8' mx={"auto"} maxW='full' py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>Forgot your password?</Heading>
          </Stack>
          <Box
            borderRadius='20px'
            bg={useColorModeValue("white", "gray.700")}
            // boxShadow={"lg"}
            minW={400}
            p={8}
          >
            <RecoverPasswordForm history={history} />
          </Box>
        </Stack>
      </Flex>
    </>
  );
}
