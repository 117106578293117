import React from "react";

import Vcontainer from "../../../app/components/Vcontainer/Vcontainer";
import { quizimportcategoryData } from "../../../app/api/quizimportcategoryData";
import PreviewImportCategory from "./PreviewImportCategory";
import Section from "../../../app/components/section/Section";

export default function StreamerSettingsQuizImportPreview() {
  return (
    <Vcontainer>
      {quizimportcategoryData.map((q) => (
        <Section mt='30px' title={`Category "${q.text}"`}>
          <PreviewImportCategory key={q.key} cat={q.key} catname={q.text} />
        </Section>
      ))}
    </Vcontainer>
  );
}
