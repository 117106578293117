import React from "react";
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";
import { Box, HStack, Stack } from "@chakra-ui/layout";

export default function EventFeedItem({ post }) {
  let summary;
  switch (post.code) {
    case "joined-event":
      summary = (
        <>
          <Link to={`/profile/${post.userUid}`}>{post.displayName}</Link> has
          signed up to <Link to={`/events/${post.eventId}`}>{post.title}</Link>
        </>
      );
      break;
    case "left-event":
      summary = (
        <>
          <Link to={`/profile/${post.userUid}`}>{post.displayName}</Link> has
          canceled their place on{" "}
          <Link to={`/events/${post.eventId}`}>{post.title}</Link>
        </>
      );
      break;

    default:
      summary = "Something happened";
      break;
  }
  return (
    <Stack>
      <HStack>
        <Box>{formatDistance(new Date(post.date), new Date())}</Box>
        <Box>{summary}</Box>
      </HStack>
    </Stack>
  );
}
