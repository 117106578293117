import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { Button } from "@chakra-ui/button";
import { useSelector } from "react-redux";
import { registerInFirebase } from "../../app/firestore/firebaseService";
import SocialLogin from "./SocialLogin";
import { Box, HStack, Stack, StackDivider, Text } from "@chakra-ui/layout";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { useHistory } from "react-router";

export default function RegisterForm() {
  // const dispatch = useDispatch();
  const history = useHistory();
  const { prevLocation } = useSelector((state) => state.auth);
  const laststreamerUrl = localStorage.getItem("laststreamerUrl");
  const laststreamerUid = localStorage.getItem("laststreamerUid");

  function handleCancel() {
    // dispatch(closeModal());
    if (history && prevLocation) {
      history.push(prevLocation);
    } else {
      history.push(laststreamerUrl ? laststreamerUrl : "/");
    }
  }

  return (
    // <ModalWrapper size='mini' header='Register'>
    <Formik
      initialValues={{ displayName: "", email: "", password: "" }}
      validationSchema={Yup.object({
        // displayName: Yup.string().required(),
        email: Yup.string().required().email(),
        password: Yup.string().required(),
      })}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          await registerInFirebase(values, laststreamerUid);
          const laststreamerUrl = localStorage.getItem("laststreamerUrl");

          setSubmitting(false);
          // dispatch(closeModal());
          // console.log(prevLocation);
          if (history && prevLocation) {
            history.push(prevLocation);
          } else {
            history.push(laststreamerUrl ? laststreamerUrl : "/");
          }
        } catch (error) {
          setErrors({ auth: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValid, dirty, errors }) => (
        <HStack>
          <Box w='45%' pr='30px'>
            <SocialLogin p='30px' />
          </Box>

          <Box w='10%' h='100%'>
            <Text fontStyle='italic' opacity='0.3'>
              or
            </Text>
          </Box>

          <Stack w='45%' pl='30px' borderLeft='1px solid rgba(255,255,255,0.2)'>
            {/* <MyDivider /> */}
            <Form>
              <FormControl>
                <Stack spacing={5}>
                  {/* <MyTextInput
                    name='displayName'
                    label='Displayname'
                    autoFocus
                  /> */}
                  <MyTextInput name='email' label='eMail' />
                  <MyTextInput
                    name='password'
                    type='password'
                    label='Password'
                  />
                  {errors.auth && (
                    <FormLabel basic color='red' style={{ marginBottom: 10 }}>
                      {errors.auth}
                    </FormLabel>
                  )}
                  <StackDivider />
                  <HStack>
                    <Button
                      isLoading={isSubmitting}
                      isDisabled={!isValid || !dirty || isSubmitting}
                      type='submit'
                      variant='primary'
                      id='id_submit'
                      w={400}
                    >
                      Register
                    </Button>
                    <Button onClick={handleCancel} variant='outline' w={100}>
                      Cancel
                    </Button>
                  </HStack>
                </Stack>
              </FormControl>
            </Form>
          </Stack>
        </HStack>
      )}
    </Formik>
    // {/* </ModalWrapper> */}
  );
}
