export const categoryData = [
  { key: "drinks", text: "Drinks", value: "drinks" },
  { key: "culture", text: "Culture", value: "culture" },
  { key: "film", text: "Film", value: "film" },
  { key: "food", text: "Food", value: "food" },
  { key: "music", text: "Music", value: "music" },
  { key: "travel", text: "Travel", value: "travel" },
];

const teamSizesOne = [
  { key: "1", text: "1", value: 1 },
  { key: "2", text: "2", value: 2 },
  { key: "3", text: "3", value: 3 },
  { key: "4", text: "4", value: 4 },
  { key: "5", text: "5", value: 5 },
  { key: "6", text: "6", value: 6 },
  { key: "7", text: "7", value: 7 },
];

const teamSizesTwo = [
  { key: "1", text: "1 (1v1)", value: 1 },
  { key: "3", text: "3 (2v2) ", value: 3 },
  { key: "5", text: "5 (3v3)", value: 5 },
  { key: "7", text: "7 (4v4)", value: 7 },
  { key: "9", text: "9 (5v5)", value: 9 },
  { key: "11", text: "11 (6v6)", value: 11 },
];

export const teamSizes = [teamSizesOne, teamSizesTwo];
