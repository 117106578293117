import React from "react";
import { useField } from "formik";
import { Textarea } from "@chakra-ui/textarea";
import { FormLabel, Badge, Box } from "@chakra-ui/react";

export default function MyTextArea({ label, ...props }) {
  const [field, meta] = useField(props);
  const id = `id_${props.name}`;
  return (
    <Box position='relative'>
      <Textarea
        mt='8'
        mb='2'
        id={id}
        isInvalid={meta.touched && !!meta.error}
        variant='flushed'
        size='lg'
        borderRadius='0'
        {...field}
        {...props}
      />
      <FormLabel htmlFor='name'>
        {label}
        {label ? ":" : ""}
      </FormLabel>

      <Badge
        marginTop='0'
        position='absolute'
        bottom='-4'
        left='0'
        colorScheme='red'
      >
        {meta.touched && meta.error ? meta.error : null}
      </Badge>
    </Box>
  );
}
