import React from "react";
import { useSelector } from "react-redux";
import TestModal from "../../../features/sandbox/TestModal";
import LoginForm from "../../../features/auth/LoginForm";
import EmailPasswordForm from "../../../features/auth/EmailPasswordForm";
import RegisterForm from "../../../features/auth/RegisterForm";
import PhotoUploadWidget from "../photos/PhotoUploadWidget";
import AccountForm from "../../../features/profiles/settings/AccountForm";
import DisplayNameForm from "../../../features/auth/DisplayNameForm";
import StreamerSettingsQuizEditForm from "../../../features/profiles/streamerSettings/StreamerSettingsQuizEditForm";
import StreamerSettingsQuizImportForm from "../../../features/profiles/streamerSettings/StreamerSettingsQuizImportForm";

import SelectorSelection from "../../../features/selectors/selectorSelection/SelectorSelection";
import QuickFinalRandom from "../../../features/selectors/quickfinalrandom/QuickFinalRandom";
import Slotmachine from "../../../features/selectors/slotmachine/Slotmachine";
import Thanos from "../../../features/selectors/thanos/Thanos";
import SortAndCut from "../../../features/selectors/sortandcut/SortAndCut";
import Quiz from "../../../features/selectors/quiz/Quiz";
import QuizUserAnswerForm from "../../../features/selectors/quiz/QuizUserAnswerForm";

export default function ModalManager() {
  const modalLookup = {
    TestModal,
    LoginForm,
    RegisterForm,
    PhotoUploadWidget,
    SelectorSelection,
    QuickFinalRandom,
    Slotmachine,
    Thanos,
    SortAndCut,
    Quiz,
    QuizUserAnswerForm,
    EmailPasswordForm,
    AccountForm,
    DisplayNameForm,
    StreamerSettingsQuizEditForm,
    StreamerSettingsQuizImportForm,
  };

  const currrentModal = useSelector((state) => state.modals);

  let renderedModal;
  if (currrentModal) {
    const { modalType, modalProps } = currrentModal;

    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
}
