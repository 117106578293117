import { Button } from "@chakra-ui/button";
import { Box, Flex, HStack, Spacer, Text } from "@chakra-ui/layout";
import React, { useState } from "react";
import { GiExitDoor } from "react-icons/gi";
import { MdAddBox } from "react-icons/md";
import { useToast } from "@chakra-ui/toast";
import { useDispatch, useSelector } from "react-redux";
import { accountTypes } from "../../../app/api/accounttypes";
import { closeModal, openModal } from "../../../app/common/modals/modalReducer";
import UnauthModal from "../../auth/UnauthModal";
import {
  joinLobbyInFirebase,
  leaveLobbyInFirebase,
} from "../../../app/firestore/firebaseService";
import {
  addEntryLobbylogInFirestore,
  getStreamersUsersStatsFirestore,
  updateUserProfile,
} from "../../../app/firestore/firestoreService";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";

export default function LobbyUserActions({
  selectedLobby,
  currentUserProfile,
  streamerProfile,
}) {
  const dispatch = useDispatch();
  const lobbyId = selectedLobby.lobbyId;
  const [modalOpen, setModalOpen] = useState(false);
  const { authenticated } = useSelector((state) => state.auth);
  const [userStreamerStats, setUserStreamerStats] = useState(false);

  const toast = useToast();

  useFirestoreDoc({
    query: () =>
      getStreamersUsersStatsFirestore(
        streamerProfile.id,
        currentUserProfile?.id
      ),
    shouldExecute: currentUserProfile?.id,
    data: (stats) => setUserStreamerStats(stats),
    deps: [dispatch, streamerProfile.id, currentUserProfile?.id],
  });

  const currentUserLobbyUserId = selectedLobby?.users?.filter(
    (u) => u.userId === currentUserProfile?.id || null
  )[0]?.id;

  const allAccountsAdded = selectedLobby.accountsNeeded?.filter(
    (type) => !currentUserProfile?.accountsKeys?.includes(type)
  ).length;

  function handleCloseAccountForm() {
    dispatch(closeModal());
  }

  async function handleJoin() {
    if (currentUserLobbyUserId) return;
    if (!userStreamerStats) return;

    try {
      await joinLobbyInFirebase(
        lobbyId,
        currentUserProfile,
        userStreamerStats
      ).then((response) => {
        updateUserProfile({
          joinedLobby: lobbyId,
          joinedStreamerURL: streamerProfile.url,
          joinedStreamerId: streamerProfile.id,
        });
      });
      await addEntryLobbylogInFirestore(
        lobbyId,
        streamerProfile.id,
        "user_join"
      );
      toast({
        title: "Lobby joined",
        description:
          "You have joined this lobby. Now wait until the next phase begins.",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  }

  async function handleLeave() {
    if (!currentUserLobbyUserId) return;

    try {
      await leaveLobbyInFirebase(lobbyId, currentUserLobbyUserId).then(
        (response) => {
          updateUserProfile({
            joinedLobby: null,
            joinedStreamerURL: null,
            joinedStreamerId: null,
          });
        }
      );
      await addEntryLobbylogInFirestore(
        lobbyId,
        streamerProfile.id,
        "user_leave"
      );
    } catch (error) {
      console.log(error.message);
      // toast.error(error.message);
    }
  }

  return (
    <>
      {modalOpen && <UnauthModal setModalOpen={setModalOpen} />}
      <Box
        m='2'
        h={currentUserLobbyUserId ? "auto" : "auto"}
        // className='smoothresize'
        style={{ overflow: "hidden" }}
      >
        {currentUserProfile &&
          selectedLobby.accountsNeeded?.length > 0 &&
          allAccountsAdded > 0 && (
            <HStack justifyContent='center' m='30px'>
              <Text>Please add account{allAccountsAdded > 1 ? "s" : ""}:</Text>

              {selectedLobby.accountsNeeded
                ?.filter(
                  (type) => !currentUserProfile?.accountsKeys?.includes(type)
                )
                .map((type) => (
                  <Button
                    key={type}
                    leftIcon={<MdAddBox />}
                    onClick={() => {
                      dispatch(
                        openModal({
                          modalType: "AccountForm",
                          modalProps: {
                            accountType: type,
                            accountTypes: accountTypes,
                            currentUserProfile: currentUserProfile,
                            handleClose: handleCloseAccountForm,
                          },
                        })
                      );
                    }}
                    aize='sm'
                    // textColor='primary'
                    variant='primary'
                  >
                    {accountTypes
                      .filter((a) => a.key === type)
                      .map((a) => a.name)}
                  </Button>
                ))}
            </HStack>
          )}
        <Flex>
          {!currentUserLobbyUserId && selectedLobby.status === "open" && (
            <>
              <Flex flex='1' flexDirection='column' justifyContent='center'>
                <Text>{selectedLobby.description}</Text>
              </Flex>
              <Box flex='1' textAlign='center'>
                <Button
                  mt='20px'
                  mb='20px'
                  variant='primary'
                  id='join_lobby'
                  disabled={currentUserProfile && allAccountsAdded}
                  size='lg'
                  onClick={
                    authenticated ? handleJoin : () => setModalOpen(true)
                  }
                >
                  Join Lobby
                </Button>
              </Box>
            </>
          )}
          <Spacer />
          {currentUserLobbyUserId && selectedLobby.status !== "all_selected" && (
            <Button size='sm' onClick={handleLeave}>
              <GiExitDoor />
              &#160; Leave Lobby
            </Button>
          )}
        </Flex>
      </Box>
    </>
  );
}
